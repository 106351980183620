/**
	Override default values for $background and $foreground palettes in the theme generated
	by material when using mat.define-X-theme().

	Ref for material palette defs.: https://github.com/angular/components/blob/master/src/material/core/theming/_palette.scss
	Ref for Shure UX: http://shureux.shure.com/prism/platforms/responsive-web/foundations/colors/
 */
@function override-defaults($theme, $foreground-overrides: (), $background-overrides: ()) {
	$color: map-get($theme, color);
	$fg: map-get($color, foreground);
	$bg: map-get($color, background);

	@return map-merge(
		$theme,
		(
			color:
				map-merge(
					$color,
					(
						foreground: map-merge($fg, $foreground-overrides),
						background: map-merge($bg, $background-overrides)
					)
				)
		)
	);
}
