@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
	$config: mat.get-color-config($theme);
	$accent: map.get($config, accent);
	$background: map.get($config, background);

	.sh-device-identify-start-button {
		color: mat.get-color-from-palette($accent);
	}

	.sh-device-identify-stop-button {
		background-color: mat.get-color-from-palette($accent);

		.sh-device-identify-stop-button-rectangle {
			background-color: mat.get-color-from-palette($background, card);
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);
	@if $color-config != null {
		@include color($theme);
	}
}
