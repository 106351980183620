@mixin override-style() {
	// Removes default styling for forms wrapping a NumberInputComponent
	.mat-form-field:has(sh-number-input) {
		.mdc-line-ripple {
			max-width: 50%;
			position: initial;
		}

		.mat-mdc-form-text-infix {
			padding: 0;
		}
	}

	.mat-mdc-form-field-focus-overlay,
	.mdc-text-field--disabled.mdc-text-field--filled,
	.mdc-text-field--filled:not(.mdc-text-field--disabled) {
		padding: 0;
		background-color: transparent !important;
	}

	.mat-mdc-form-field-infix {
		min-height: 48px;
		width: auto !important;
	}

	.mat-mdc-text-field-wrapper,
	.mat-mdc-form-field-hint-wrapper,
	.mat-mdc-form-field-error-wrapper {
		padding: 0 !important;
	}

	// Remove designated space for the label, for form fields which have no label
	.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
		padding-top: 0;
		padding-bottom: 0;
		min-height: 24px;
	}
}
