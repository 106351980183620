@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
	$config: mat.get-color-config($theme);
	$accent: map.get($config, accent);

	sh-id-button {
		.sh-device-identifying {
			animation: sh-device-identify-bounce 1.1s infinite ease-in-out;

			color: mat.get-color-from-palette($accent);
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);
	@if $color-config != null {
		@include color($theme);
	}
}

@keyframes sh-device-identify-bounce {
	100% {
		transform: scale(1);
	}
	12% {
		transform: scale(1.28);
	}
	90% {
		transform: scale(1);
	}
}
