@mixin mat-sidenav-override() {
	.mat-sidenav {
		.mat-form-field,
		.mat-form-field-infix,
		form {
			width: 100% !important;
		}

		.mat-list .mat-list-item {
			height: auto;
		}

		.mat-expansion-panel-header {
			padding: 0 16px;
		}

		.mat-expansion-panel-body {
			padding: 16px 0;

			.mat-form-field {
				margin-left: 0;
			}
		}

		textarea {
			overflow-x: hidden;
		}
	}

	.mat-drawer-inner-container::-webkit-scrollbar-button,
	.mat-drawer-inner-container::-webkit-scrollbar-track-piece,
	.mat-drawer-inner-container::-webkit-scrollbar-corner,
	.mat-drawer-inner-container::-webkit-resizer {
		display: none;
	}

	.mat-drawer-inner-container:hover::-webkit-scrollbar {
		display: initial;
	}

	.mat-drawer-inner-container::-webkit-scrollbar {
		display: none;
	}

	.mat-drawer-inner-container {
		overflow-y: overlay !important;
	}
}

@mixin mat-sidenav-theme-override($theme) {
}
