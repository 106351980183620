@mixin mat-form-override() {
	.sh-disabled-text {
		&.mat-form-field-disabled .mat-form-field-underline,
		&.mat-form-field-type-mat-input .mat-form-field-underline {
			display: none;
		}

		&.mat-form-field-disabled input {
			pointer-events: none;
		}
		.mdc-text-field--disabled .mdc-line-ripple::before {
			border: none !important;
		}
	}
}

@mixin mat-form-theme-override($theme) {
	$accent: map-get($theme, accent);

	@if (map-get($theme, 'is-dark') == false) {
		.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
			color: mat-color($accent, 'darker');
		}
		.mat-accent .mat-input-element {
			caret-color: mat-color($accent, 'darker');
		}
		.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
			background-color: mat-color($accent, 'darker');
		}
	}
}
