@use 'sass:map';
@use '@angular/material' as mat;

/**
 * `sh-inline-link` defines the style for a inline-link w. a underline
 */
@mixin theme($theme) {
	$config: mat.get-color-config($theme);
	$foreground: map.get($config, foreground);
	$accent: map.get($config, accent);

	.sh-inline-link {
		cursor: pointer;
		color: mat.get-color-from-palette($foreground, text);
		text-decoration: underline;
		text-decoration-color: mat.get-color-from-palette($accent);
		text-decoration-thickness: 2px;
		text-underline-offset: 2px;
	}
}
