// See https://material.angular.io/cdk/layout/overview#predefined-breakpoints
// Keep in sync with breakpoint.service.ts

@mixin lte-xsmall {
	@media (max-width: 599.98px) {
		@content;
	}
}

@mixin lte-small {
	@media (max-width: 959.98px) {
		@content;
	}
}

@mixin gte-small {
	@media (min-width: 600px) {
		@content;
	}
}

@mixin lte-medium {
	@media (max-width: 1279.98px) {
		@content;
	}
}

@mixin gte-medium {
	@media (min-width: 960px) {
		@content;
	}
}

@mixin gte-large {
	@media (min-width: 1280px) {
		@content;
	}
}

@mixin handset-portrait {
	@media (max-width: 599.98px) and (orientation: portrait) {
		@content;
	}
}

@mixin handset-landscape {
	@media (max-width: 959.98px) and (orientation: landscape) {
		@content;
	}
}

// Adds circular background interaction indicator to icon-style elements (icon buttons, checkboxes, etc.)
// See https://www.figma.com/file/LzItmcIdRMMfX68BT8gwTo/Shared-%2F-Prism-Web-and-Angular-Components?type=design&node-id=749%3A29632&mode=design&t=O0FBxMzccIMkwUiZ-1
@mixin icon-interaction-states($icon-size, $bg-size) {
	position: relative;

	&::after {
		// Center the background circle by subtracting half the icon size from half the background size
		$offset: (($bg-size / 2) - ($icon-size / 2)) * -1;

		content: '';
		position: absolute;
		top: $offset;
		left: $offset;
		width: $bg-size;
		height: $bg-size;
		border-radius: 50%;
		opacity: 0;
		transition: opacity 0.3s;
		pointer-events: none; // required to let clicks pass through to AG Grid elements
	}

	&:hover::after {
		opacity: 1;
		background-color: color-mix(in srgb, var(--prism-color-theme-window-film-hover), transparent 12%);
	}

	&:focus::after {
		opacity: 1;
		background-color: color-mix(in srgb, var(--prism-color-theme-window-film-focused), transparent 30%);
	}
}
