@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
	$color-config: mat.get-color-config($theme);
	$accent: map.get($color-config, accent);

	.sh-snackbar-dismiss-button {
		color: mat.get-color-from-palette($accent) !important;
	}

	.mdc-snackbar__label {
		color: white !important;
	}
}

// Complete theme for the library.
@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);
	@if $color-config != null {
		@include color($theme);
	}
}
