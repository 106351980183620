@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
	$color-config: mat.get-color-config($theme);

	.sh-device-image {
		&.sh-virtual-device {
			filter: if(map.get($color-config, is-dark), invert(1), none);
		}
	}
}

// Complete theme for the library.
@mixin theme($theme) {
	$color-config: mat.get-color-config($theme);
	@if $color-config != null {
		@include color($theme);
	}
}
