/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@use 'mixins';
@use './styles/theming.scss' as theming;
@use '../../../../libs/shared/angular/theming/src/styles/material-overrides/material-overrides.scss' as
	material-override;
@use '../../../../libs/shared/angular/components/src/lib/legacy-layout/structure.scss' as structure;

@include theming.app-theming();
@include material-override.all-components-override-style();

@import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
@import '../../../../libs/shared/angular/components/src/lib/legacy-layout/material-override.scss';

@import '../../../../libs/cloud-apps/devices/feature-devices/src/lib/view/components/devices.component.theme.scss';

/**
 * Prism design tokens
 */
@import '@shure/prism-design-tokens-css-core/vars-flat.css';
// @import '@shure/prism-design-tokens-css-core/vars-light.css'; // TODO: re-add once we support light mode
@import '@shure/prism-design-tokens-css-core/vars-dark.css';

/**
 * AG Grid styles
 */
@import 'ag-grid-community/styles/ag-grid.min.css';
@import 'ag-grid-community/styles/ag-theme-balham-no-font.min.css';
@import 'ag-grid-community/styles/agGridMaterialFont.css';

// Grid style overrides. These MUST be set globally so popups anchored to the body element receive the correct styles
.ag-theme-balham-dark {
	--ag-icon-font-family: 'agGridMaterial';
	--ag-foreground-color: var(--prism-color-theme-ink);
	--ag-background-color: var(--prism-color-theme-surface-variant);
	--ag-odd-row-background-color: var(--prism-color-theme-surface-variant);
	--ag-header-foreground-color: var(--prism-color-theme-ink);
	--ag-header-background-color: var(--prism-color-theme-surface-variant);
	--ag-header-cell-hover-background-color: var(--prism-color-theme-surface-variant);
	--ag-header-column-separator-color: var(--prism-color-theme-outline);
	--ag-header-column-separator-width: 1px;
	--ag-borders-critical: 1px solid; // non-row border style
	--ag-border-color: var(--prism-color-theme-outline); // non-row border color
	--ag-border-radius: 8px; // standard border radius
	--ag-row-border-color: var(--prism-color-theme-outline); // row bottom border color
	--ag-row-border-width: 1px;
	--ag-selected-row-background-color: color-mix(
		in srgb,
		var(--prism-color-theme-surface-variant),
		var(--prism-color-theme-outline)
	);
	--ag-row-hover-color: color-mix(
		in srgb,
		var(--prism-color-theme-surface-variant),
		var(--prism-color-theme-window-film-hover)
	);
	--ag-balham-active-color: var(--prism-color-theme-accent-primary);
	--ag-material-accent-color: var(--prism-color-theme-accent-primary);
	--ag-input-focus-border-color: var(--prism-color-theme-accent-primary);
	--ag-checkbox-background-color: transparent;
	--ag-checkbox-unchecked-color: var(--prism-color-theme-ink);
	--ag-checkbox-checked-color: var(--prism-color-theme-accent-primary);
	--ag-checkbox-indeterminate-color: var(--prism-color-theme-accent-primary);
}

.ag-cell {
	display: flex;
	align-items: center;
}

.ag-header-cell-text {
	font-weight: 700;
}

.ag-header-icon.ag-header-cell-menu-button {
	@include mixins.icon-interaction-states($icon-size: 16px, $bg-size: 28px);
	background-color: transparent;
	border: none;
	cursor: pointer;
}

// Make column container expand to full width so that row borders aren't cut short
.ag-center-cols-container {
	min-width: 100%;
}

.ag-row-selected.ag-row-hover {
	background-color: color-mix(in srgb, var(--ag-selected-row-background-color), var(--ag-row-hover-color));
}

.ag-row-last {
	border-bottom: none;
}

.ag-row-group {
	align-items: center !important;
}

.ag-selection-checkbox .ag-checkbox {
	@include mixins.icon-interaction-states($icon-size: 16px, $bg-size: 32px);
}

.ag-checkbox-input-wrapper:focus-within {
	box-shadow: none;
}

.ag-checkbox-input {
	cursor: pointer;
}

// Necessary for popups anchored to the body element to show on top of dialogs
.ag-popup-child {
	z-index: 9999;
}

// These styles apply to the drag indicator that is shown when dragging a column to move it.
// Without `max-width`, it takes up the entire width of the screen. We also need to override
// `height` so that it can expand to fully show the drag indicator icon and column name.
.ag-dnd-ghost {
	width: 100%;
	max-width: 300px;
	height: auto !important;
	line-height: normal;
	padding: 4px;
	background-color: var(--prism-color-theme-surface);
}

// Override the top padding on the no rows overlay so that it appears centered in the grid
#sh-no-rows-overlay-div {
	margin-top: var(--ag-row-height) !important;
}

$sh-layout-padding: 24px !default;
$sh-content-padding: 24px !default;

.layout-padding,
.sh-main-content-container,
.sh-page-padding {
	padding: $sh-layout-padding;
	z-index: 0;
}
[sh-layout-padding],
.sh-layout-padding {
	padding: $sh-layout-padding;
}

[sh-content-padding],
.sh-content-padding {
	padding: $sh-content-padding;
}

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;

	sh-root {
		display: flex;
		flex: 1;
		flex-direction: column;
		box-sizing: border-box;
		min-height: 100%;
		width: 100%;
		height: 100%;
	}

	.sh-custom-dialog-container .mat-mdc-dialog-container {
		padding: 0;
	}

	// Allow snackbar to determine it's own height and display on a single line
	.mat-mdc-snack-bar-container {
		height: auto;
		max-width: 100vw;
	}

	// Positions snackbar with a vertical position of 'bottom' above the app shell footer
	.sh-center-snackbar {
		margin-bottom: 60px;
	}

	.sh-center-text input {
		text-align: center;
	}
}

/*
	Flex <router-outlet> elements by default allowing
	the routed components to flex as well
*/
router-outlet:not([noFlex]) {
	flex: none !important;

	+ * {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}
}

sh-button-link {
	box-sizing: border-box !important;
	border-top: 2px solid transparent !important;
	border-bottom: 2px solid var(--mat-accent-darker) !important;
	padding: 0 2px !important;
	display: inline-block !important;
	border-radius: 0 !important;
	height: 24px !important;
	line-height: 22px !important;
	min-width: initial !important;
}

/////////////////////////////////////////
/// TODO LBF 7/26/23 - pulled in from light-roboto.css - fix appropriately for Angular 15
.mat-mdc-button,
.mat-mdc-fab,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
	font-family: Roboto, Helvetica Neue, sans-serif;
	font-size: 14px !important;
	font-weight: 600 !important;
}

.mat-mdc-raised-button:not([disabled]) {
	color: var(--mat-shure-dark-text) !important;
	background-color: var(--mat-shure-green) !important;
}

.mat-expansion-panel-header {
	font-family: Roboto, Helvetica Neue, sans-serif !important;
	font-size: 13px !important;
	font-weight: 400 !important;
}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
	background: #222222;
}
@media (hover: none) {
	.mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {
		background: #fff;
	}
}
.mat-expansion-panel-header-title {
	color: #fbfbfb;
}
.mat-expansion-indicator:after,
.mat-expansion-panel-header-description {
	color: #fbfbfb;
}
.mat-expansion-panel-header[aria-disabled='true'] {
	color: rgba(0, 0, 0, 0.38);
}
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description,
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title {
	color: inherit;
}
.mat-expansion-panel-header {
	height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
	height: 64px;
}

.mat-mdc-input-element {
	font-size: 16px !important;
	color: var(--mat-shure-dark-input) !important;
}

/////////////////////////////////////////
.sh-button-link:hover {
	background-color: var(--mat-accent-default) !important;
	border-bottom: 2px solid var(--mat-accent-default) !important;
}

.sh-button-link:focus-visible {
	padding: 0 !important;
	border: 2px solid var(--mat-accent-darker) !important;
}
.sh-button-link:active {
	background-color: var(--mat-accent-lighter) !important;
	border-bottom: 2px solid var(--mat-accent-lighter) !important;
}
.sh-button-link[disabled] {
	pointer-events: none;
	border-bottom: 2px dotted var(--mat-foreground-disabled) !important;
}

.sh-main-content-container [class*='mat-elevation-z4'] {
	border-radius: 8px;
	overflow: hidden;
}

.mat-mdc-tooltip {
	white-space: pre-wrap;
}

.sh-ellipsis {
	text-overflow: ellipsis !important;
	overflow: hidden !important;
	white-space: nowrap !important;
}

.sh-ellipsis-white-space-none {
	white-space: none !important;
}
.mat-primary.mat-mdc-button-base {
	height: 32px !important;
}
.mat-icon {
	vertical-align: top;
}

/*
 * GLOBAL COMPONENT STYLES
 * These styles are used across CDM on various components. Until we have a full design-system/component library,
 * we can just add them here.
 */

// Button with green text and transparent background that is visible on hover
// Use with mat-button directive
.mat-mdc-button.sh-button-ghost {
	&:not(:disabled) {
		color: var(--prism-color-theme-accent-primary);
	}

	&[disabled] {
		color: var(--prism-color-theme-disabled-contrast);
	}
}

style {
	display: none !important;
}
