@use '@angular/material' as mat;

/**
 *	Pre-built themes
 */
@use '../../../../../libs/shared/angular/theming/src/typography/typography.scss' as typography;
@use '../../../../../libs/shared/angular/theming/src/themes/prebuilt/light.theme.scss' as light;
@use '../../../../../libs/shared/angular/theming/src/themes/prebuilt/dark.theme.scss' as dark;
@use '../../../../../libs/shared/angular/theming/src/themes/prebuilt/colors.scss' as colors;

/**
 *	Shared library themes
 */
@use '../../../../../libs/shared/angular/components/src/lib/components.theme.scss' as components;

/**
 *	Device library themes
 */
// @use '../../../../../libs/device-ui/mxa920/feature-settings/src/lib/settings.theme.scss' as mxa920Settings;

/**
 *	App feature library themes
 */

@use '../../../../../libs//cloud-apps//devices/feature-devices/src/lib/view/components/devices.component.theme.scss' as
	devices;
@use '../../../../../libs//cloud-apps/devices/shared/components/src/lib/component-properties.theme.scss' as
	component-properties;

/**
 * Mixin for including **all** internal library themes.
 * To add a new library import it using the `@use '../..' as x;` and add it to the mixin below
 * using the `@include x.theme($theme)`;
 */
@mixin all-library-themes($theme, $colors) {
	// Shared libraries
	@include components.all-component-theme($theme, $colors);

	// App feature libraries
	@include devices.theme($theme, $colors);
	@include component-properties.theme($theme, $colors);
}

/**
 * Mixin for applying themes to the application.
 */
@mixin app-theming() {
	@include mat.typography-hierarchy(typography.$typography-config);
	@include mat.all-component-typographies(typography.$typography-config);
	@include mat.core();

	@include mat.all-component-themes(dark.$theme);

	@include dark.theme();
	@include all-library-themes(dark.$theme, colors.$led-colors-light);

	// Dark theme definition by attribute
	// [theme='dark'] {
	// 	@include mat.all-component-colors(dark.$theme);

	// 	@include dark.theme();
	// 	@include all-library-themes(dark.$theme, colors.$led-colors-dark);
	// }
}

// TODO: LBF 7/30/2023 - pulled from light-roboto.css
// light theme
:root {
	--mat-background-card: #222222;
	--mat-background-card-rgb: 255, 255, 255;
	--mat-shure-green: #b2ff33;
	--mat-shure-disabled-text: rgba(123, 155, 61, 255);
	--mat-shure-dark-input: #dbdbdb;
	--mat-shure-dark-text: #000;
	--mat-shure-dark-background: #000;
	--mat-shure-lightdark-background: #222;
	--mat-shure-darktheme-foreground: #fff;
	--mat-shure-darktheme-background-disabled: rgba(255, 255, 255, 0.12);
}
