@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme, $colors) {
	$config: mat.get-color-config($theme);
	$warn: map.get($config, warn);

	sh-mute-button {
		.sh-muted {
			background-color: map.get($colors, 'muted');
			color: if(map.get($config, is-dark), black, white);
		}

		.sh-some-devices-muted {
			background-color: rgba(map.get($colors, 'muted'), 0.38);
		}
	}
}

@mixin theme($theme, $colors) {
	$color-config: mat.get-color-config($theme);
	@if $color-config != null {
		@include color($theme, $colors);
	}
}
