@use 'sass:map';

@mixin color($led-colors) {
	sh-device-name-renderer {
		.sh-warning-icon {
			color: map.get($led-colors, 'charging') !important;
		}
	}
}

@mixin theme($led-colors) {
	@if $led-colors != null {
		@include color($led-colors);
	}
}
