@mixin override-style() {
	.mat-mdc-button-base {
		border-radius: 16px !important;
		line-height: 32px !important;
	}

	.mat-mdc-button-base:not(.mat-mdc-icon-button) {
		height: 32px !important;
	}

	.mat-mdc-button-touch-target {
		height: inherit !important;
		width: inherit !important;
		border-radius: inherit !important;
	}
}
