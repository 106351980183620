@font-face {
	font-family: 'prism-mat-sharp';
	src: url('fonts/prism-mat-sharp.eot?fxlo7u');
	src: url('fonts/prism-mat-sharp.eot?fxlo7u#iefix') format('embedded-opentype'),
		url('fonts/prism-mat-sharp.ttf?fxlo7u') format('truetype'),
		url('fonts/prism-mat-sharp.woff?fxlo7u') format('woff'),
		url('fonts/prism-mat-sharp.svg?fxlo7u#prism-mat-sharp') format('svg');
	font-weight: normal;
	font-style: normal;
}
.prism-mat-sharp {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'prism-mat-sharp' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 24px;
}

.prism-mat-sharp.ic_spectrum_mask:before {
	content: '\ed5e';
}
.prism-mat-sharp.sh-meeting_room_outline-24px:before {
	content: '\ed5f';
}
.prism-mat-sharp.sh-lock:before {
	content: '\ed5a';
}
.prism-mat-sharp.sh-unlock:before {
	content: '\ed5c';
}
.prism-mat-sharp.sh-gn:before {
	content: '\ed59';
}
.prism-mat-sharp.sh-bn:before {
	content: '\ed58';
}
.prism-mat-sharp.sh-bp:before {
	content: '\ed5b';
}
.prism-mat-sharp.sh-hh:before {
	content: '\ed5d';
}
.prism-mat-sharp.sh-3bars1:before {
	content: '\ed1b';
}
.prism-mat-sharp.sh-3bars2:before {
	content: '\ed1c';
}
.prism-mat-sharp.sh-3bars3:before {
	content: '\ed1d';
}
.prism-mat-sharp.sh-battery-50:before {
	content: '\ed1e';
}
.prism-mat-sharp.sh-chevron-double-left:before {
	content: '\ed1f';
}
.prism-mat-sharp.sh-control:before {
	content: '\ed20';
}
.prism-mat-sharp.sh-coverage:before {
	content: '\ed21';
}
.prism-mat-sharp.sh-devices:before {
	content: '\ed22';
}
.prism-mat-sharp.sh-dish:before {
	content: '\ed23';
}
.prism-mat-sharp.sh-encryption-disabled:before {
	content: '\ed24';
}
.prism-mat-sharp.sh-encryption-error:before {
	content: '\ed25';
}
.prism-mat-sharp.sh-eq-bypass:before {
	content: '\ed26';
}
.prism-mat-sharp.sh-eq:before {
	content: '\ed27';
}
.prism-mat-sharp.sh-error-outline:before {
	content: '\ed28';
}
.prism-mat-sharp.sh-eventlog-outline:before {
	content: '\ed29';
}
.prism-mat-sharp.sh-eventlog:before {
	content: '\ed2a';
}
.prism-mat-sharp.sh-export:before {
	content: '\ed2b';
}
.prism-mat-sharp.sh-filter:before {
	content: '\ed2c';
}
.prism-mat-sharp.sh-folder-move:before {
	content: '\ed2d';
}
.prism-mat-sharp.sh-folder-remove:before {
	content: '\ed2e';
}
.prism-mat-sharp.sh-highcut-nofill:before {
	content: '\ed2f';
}
.prism-mat-sharp.sh-highcut:before {
	content: '\ed30';
}
.prism-mat-sharp.sh-highshelf-nofill:before {
	content: '\ed31';
}
.prism-mat-sharp.sh-highshelf:before {
	content: '\ed32';
}
.prism-mat-sharp.sh-id-nobox:before {
	content: '\ed33';
}
.prism-mat-sharp.sh-id:before {
	content: '\ed34';
}
.prism-mat-sharp.sh-import:before {
	content: '\ed35';
}
.prism-mat-sharp.sh-location-templates:before {
	content: '\ed36';
}
.prism-mat-sharp.sh-location:before {
	content: '\ed37';
}
.prism-mat-sharp.sh-lowcut-nofill:before {
	content: '\ed38';
}
.prism-mat-sharp.sh-lowcut:before {
	content: '\ed39';
}
.prism-mat-sharp.sh-lowshelf-nofill:before {
	content: '\ed3a';
}
.prism-mat-sharp.sh-lowshelf:before {
	content: '\ed3b';
}
.prism-mat-sharp.sh-miccfg:before {
	content: '\ed3c';
}
.prism-mat-sharp.sh-muteall:before {
	content: '\ed3d';
}
.prism-mat-sharp.sh-nexton:before {
	content: '\ed3e';
}
.prism-mat-sharp.sh-parametric-nofill:before {
	content: '\ed3f';
}
.prism-mat-sharp.sh-parametric:before {
	content: '\ed40';
}
.prism-mat-sharp.sh-power:before {
	content: '\ed41';
}
.prism-mat-sharp.sh-presets-1:before {
	content: '\ed42';
}
.prism-mat-sharp.sh-presets-2:before {
	content: '\ed43';
}
.prism-mat-sharp.sh-presets-3:before {
	content: '\ed44';
}
.prism-mat-sharp.sh-presets-4:before {
	content: '\ed45';
}
.prism-mat-sharp.sh-presets-5:before {
	content: '\ed46';
}
.prism-mat-sharp.sh-presets-6:before {
	content: '\ed47';
}
.prism-mat-sharp.sh-presets-7:before {
	content: '\ed48';
}
.prism-mat-sharp.sh-presets-8:before {
	content: '\ed49';
}
.prism-mat-sharp.sh-presets-9:before {
	content: '\ed4a';
}
.prism-mat-sharp.sh-presets-10:before {
	content: '\ed4b';
}
.prism-mat-sharp.sh-raised-hand:before {
	content: '\ed4c';
}
.prism-mat-sharp.sh-rf:before {
	content: '\ed4d';
}
.prism-mat-sharp.sh-signalflow:before {
	content: '\ed4e';
}
.prism-mat-sharp.sh-sort-az:before {
	content: '\ed4f';
}
.prism-mat-sharp.sh-standby:before {
	content: '\ed50';
}
.prism-mat-sharp.sh-thermometer:before {
	content: '\ed51';
}
.prism-mat-sharp.sh-thumbtack:before {
	content: '\ed52';
}
.prism-mat-sharp.sh-timer-sand-empty:before {
	content: '\ed53';
}
.prism-mat-sharp.sh-timer-sand:before {
	content: '\ed54';
}
.prism-mat-sharp.sh-voting:before {
	content: '\ed55';
}
.prism-mat-sharp.sh-warning-outline:before {
	content: '\ed56';
}
.prism-mat-sharp.sh-mic-external:before {
	content: '\ed57';
}
.prism-mat-sharp.check_box_outline_blank:before {
	content: '\e901';
}
.prism-mat-sharp.check_box:before {
	content: '\e902';
}
.prism-mat-sharp.indeterminate_check_box:before {
	content: '\e903';
}
.prism-mat-sharp.radio_button_checked:before {
	content: '\e904';
}
.prism-mat-sharp.radio_button_unchecked:before {
	content: '\e905';
}
.prism-mat-sharp.star_border_purple500:before {
	content: '\e906';
}
.prism-mat-sharp.star_border:before {
	content: '\e907';
}
.prism-mat-sharp.star_half:before {
	content: '\e908';
}
.prism-mat-sharp.star_outline:before {
	content: '\e909';
}
.prism-mat-sharp.star_purple500:before {
	content: '\e90a';
}
.prism-mat-sharp.star:before {
	content: '\e90b';
}
.prism-mat-sharp.toggle_off:before {
	content: '\e90c';
}
.prism-mat-sharp.toggle_on:before {
	content: '\e90d';
}
.prism-mat-sharp.cake:before {
	content: '\e90e';
}
.prism-mat-sharp.domain:before {
	content: '\e90f';
}
.prism-mat-sharp.group_add:before {
	content: '\e910';
}
.prism-mat-sharp.group:before {
	content: '\e911';
}
.prism-mat-sharp.location_city:before {
	content: '\e912';
}
.prism-mat-sharp.mood_bad:before {
	content: '\e913';
}
.prism-mat-sharp.mood:before {
	content: '\e914';
}
.prism-mat-sharp.notifications_active:before {
	content: '\e915';
}
.prism-mat-sharp.notifications_none:before {
	content: '\e916';
}
.prism-mat-sharp.notifications_off:before {
	content: '\e917';
}
.prism-mat-sharp.notifications_paused:before {
	content: '\e918';
}
.prism-mat-sharp.notifications:before {
	content: '\e919';
}
.prism-mat-sharp.pages:before {
	content: '\e91a';
}
.prism-mat-sharp.party_mode:before {
	content: '\e91b';
}
.prism-mat-sharp.people_outline:before {
	content: '\e91c';
}
.prism-mat-sharp.people:before {
	content: '\e91d';
}
.prism-mat-sharp.person_add:before {
	content: '\e91e';
}
.prism-mat-sharp.person_outline:before {
	content: '\e91f';
}
.prism-mat-sharp.person:before {
	content: '\e920';
}
.prism-mat-sharp.plus_one:before {
	content: '\e921';
}
.prism-mat-sharp.poll:before {
	content: '\e922';
}
.prism-mat-sharp.public:before {
	content: '\e923';
}
.prism-mat-sharp.school:before {
	content: '\e924';
}
.prism-mat-sharp.sentiment_dissatisfied:before {
	content: '\e925';
}
.prism-mat-sharp.sentiment_neutral:before {
	content: '\e926';
}
.prism-mat-sharp.sentiment_satisfied:before {
	content: '\e927';
}
.prism-mat-sharp.sentiment_very_dissatisfied:before {
	content: '\e928';
}
.prism-mat-sharp.sentiment_very_satisfied:before {
	content: '\e929';
}
.prism-mat-sharp.share:before {
	content: '\e92a';
}
.prism-mat-sharp.thumb_down_alt:before {
	content: '\e92b';
}
.prism-mat-sharp.thumb_up_alt:before {
	content: '\e92c';
}
.prism-mat-sharp.whatshot:before {
	content: '\e92d';
}
.prism-mat-sharp.ac_unit:before {
	content: '\e92e';
}
.prism-mat-sharp.airport_shuttle:before {
	content: '\e92f';
}
.prism-mat-sharp.all_inclusive:before {
	content: '\e930';
}
.prism-mat-sharp.beach_access:before {
	content: '\e931';
}
.prism-mat-sharp.business_center:before {
	content: '\e932';
}
.prism-mat-sharp.casino:before {
	content: '\e933';
}
.prism-mat-sharp.child_care:before {
	content: '\e934';
}
.prism-mat-sharp.child_friendly:before {
	content: '\e935';
}
.prism-mat-sharp.fitness_center:before {
	content: '\e936';
}
.prism-mat-sharp.free_breakfast:before {
	content: '\e937';
}
.prism-mat-sharp.golf_course:before {
	content: '\e938';
}
.prism-mat-sharp.hot_tub:before {
	content: '\e939';
}
.prism-mat-sharp.kitchen:before {
	content: '\e93a';
}
.prism-mat-sharp.meeting_room:before {
	content: '\e93b';
}
.prism-mat-sharp.no_meeting_room:before {
	content: '\e93c';
}
.prism-mat-sharp.pool:before {
	content: '\e93d';
}
.prism-mat-sharp.room_service:before {
	content: '\e93e';
}
.prism-mat-sharp.rv_hookup:before {
	content: '\e93f';
}
.prism-mat-sharp.smoke_free:before {
	content: '\e940';
}
.prism-mat-sharp.smoking_rooms:before {
	content: '\e941';
}
.prism-mat-sharp.spa:before {
	content: '\e942';
}
.prism-mat-sharp.adb:before {
	content: '\e943';
}
.prism-mat-sharp.airline_seat_flat_angled:before {
	content: '\e944';
}
.prism-mat-sharp.airline_seat_flat:before {
	content: '\e945';
}
.prism-mat-sharp.airline_seat_individual_suite:before {
	content: '\e946';
}
.prism-mat-sharp.airline_seat_legroom_extra:before {
	content: '\e947';
}
.prism-mat-sharp.airline_seat_legroom_normal:before {
	content: '\e948';
}
.prism-mat-sharp.airline_seat_legroom_reduced:before {
	content: '\e949';
}
.prism-mat-sharp.airline_seat_recline_extra:before {
	content: '\e94a';
}
.prism-mat-sharp.airline_seat_recline_normal:before {
	content: '\e94b';
}
.prism-mat-sharp.bluetooth_audio:before {
	content: '\e94c';
}
.prism-mat-sharp.confirmation_number:before {
	content: '\e94d';
}
.prism-mat-sharp.disc_full:before {
	content: '\e94e';
}
.prism-mat-sharp.do_disturb_alt:before {
	content: '\e94f';
}
.prism-mat-sharp.do_disturb_off:before {
	content: '\e950';
}
.prism-mat-sharp.do_disturb_on:before {
	content: '\e951';
}
.prism-mat-sharp.do_disturb:before {
	content: '\e952';
}
.prism-mat-sharp.drive_eta:before {
	content: '\e953';
}
.prism-mat-sharp.enhanced_encryption:before {
	content: '\e954';
}
.prism-mat-sharp.event_available:before {
	content: '\e955';
}
.prism-mat-sharp.event_busy:before {
	content: '\e956';
}
.prism-mat-sharp.event_note:before {
	content: '\e957';
}
.prism-mat-sharp.folder_special:before {
	content: '\e958';
}
.prism-mat-sharp.live_tv:before {
	content: '\e959';
}
.prism-mat-sharp.mms:before {
	content: '\e95a';
}
.prism-mat-sharp.more:before {
	content: '\e95b';
}
.prism-mat-sharp.network_check:before {
	content: '\e95c';
}
.prism-mat-sharp.network_locked:before {
	content: '\e95d';
}
.prism-mat-sharp.no_encryption_gmailerrorred:before {
	content: '\e95e';
}
.prism-mat-sharp.no_encryption:before {
	content: '\e95f';
}
.prism-mat-sharp.ondemand_video:before {
	content: '\e960';
}
.prism-mat-sharp.personal_video:before {
	content: '\e961';
}
.prism-mat-sharp.phone_bluetooth_speaker:before {
	content: '\e962';
}
.prism-mat-sharp.phone_callback:before {
	content: '\e963';
}
.prism-mat-sharp.phone_forwarded:before {
	content: '\e964';
}
.prism-mat-sharp.phone_in_talk:before {
	content: '\e965';
}
.prism-mat-sharp.phone_locked:before {
	content: '\e966';
}
.prism-mat-sharp.phone_missed:before {
	content: '\e967';
}
.prism-mat-sharp.phone_paused:before {
	content: '\e968';
}
.prism-mat-sharp.power_off:before {
	content: '\e969';
}
.prism-mat-sharp.power:before {
	content: '\e96a';
}
.prism-mat-sharp.priority_high:before {
	content: '\e96b';
}
.prism-mat-sharp.rv_hookup1:before {
	content: '\e96c';
}
.prism-mat-sharp.sd_card_alert:before {
	content: '\e96d';
}
.prism-mat-sharp.sd_card:before {
	content: '\e96e';
}
.prism-mat-sharp.sms_failed:before {
	content: '\e96f';
}
.prism-mat-sharp.sms:before {
	content: '\e970';
}
.prism-mat-sharp.sync_disabled:before {
	content: '\e971';
}
.prism-mat-sharp.sync_problem:before {
	content: '\e972';
}
.prism-mat-sharp.sync:before {
	content: '\e973';
}
.prism-mat-sharp.system_update:before {
	content: '\e974';
}
.prism-mat-sharp.tap_and_play:before {
	content: '\e975';
}
.prism-mat-sharp.time_to_leave:before {
	content: '\e976';
}
.prism-mat-sharp.tv_off:before {
	content: '\e977';
}
.prism-mat-sharp.vibration:before {
	content: '\e978';
}
.prism-mat-sharp.voice_chat:before {
	content: '\e979';
}
.prism-mat-sharp.vpn_lock:before {
	content: '\e97a';
}
.prism-mat-sharp.wc:before {
	content: '\e97b';
}
.prism-mat-sharp.wifi_off:before {
	content: '\e97c';
}
.prism-mat-sharp.wifi:before {
	content: '\e97d';
}
.prism-mat-sharp.apps:before {
	content: '\e97e';
}
.prism-mat-sharp.arrow_back_ios:before {
	content: '\e97f';
}
.prism-mat-sharp.arrow_back:before {
	content: '\e980';
}
.prism-mat-sharp.arrow_downward:before {
	content: '\e981';
}
.prism-mat-sharp.arrow_drop_down_circle:before {
	content: '\e982';
}
.prism-mat-sharp.arrow_drop_down:before {
	content: '\e983';
}
.prism-mat-sharp.arrow_drop_up:before {
	content: '\e984';
}
.prism-mat-sharp.arrow_forward_ios:before {
	content: '\e985';
}
.prism-mat-sharp.arrow_forward:before {
	content: '\e986';
}
.prism-mat-sharp.arrow_left:before {
	content: '\e987';
}
.prism-mat-sharp.arrow_right:before {
	content: '\e988';
}
.prism-mat-sharp.arrow_upward:before {
	content: '\e989';
}
.prism-mat-sharp.cancel:before {
	content: '\e98a';
}
.prism-mat-sharp.check:before {
	content: '\e98b';
}
.prism-mat-sharp.chevron_left:before {
	content: '\e98c';
}
.prism-mat-sharp.chevron_right:before {
	content: '\e98d';
}
.prism-mat-sharp.close:before {
	content: '\e98e';
}
.prism-mat-sharp.expand_less:before {
	content: '\e98f';
}
.prism-mat-sharp.expand_more:before {
	content: '\e990';
}
.prism-mat-sharp.first_page:before {
	content: '\e991';
}
.prism-mat-sharp.fullscreen_exit:before {
	content: '\e992';
}
.prism-mat-sharp.fullscreen:before {
	content: '\e993';
}
.prism-mat-sharp.last_page:before {
	content: '\e994';
}
.prism-mat-sharp.menu:before {
	content: '\e995';
}
.prism-mat-sharp.more_horiz:before {
	content: '\e996';
}
.prism-mat-sharp.more_vert:before {
	content: '\e997';
}
.prism-mat-sharp.refresh:before {
	content: '\e998';
}
.prism-mat-sharp.subdirectory_arrow_left:before {
	content: '\e999';
}
.prism-mat-sharp.subdirectory_arrow_right:before {
	content: '\e99a';
}
.prism-mat-sharp.unfold_less:before {
	content: '\e99b';
}
.prism-mat-sharp.unfold_more:before {
	content: '\e99c';
}
.prism-mat-sharp.uniE99C:before {
	content: '\e99d';
}
.prism-mat-sharp.add_location:before {
	content: '\e99e';
}
.prism-mat-sharp.atm:before {
	content: '\e99f';
}
.prism-mat-sharp.beenhere:before {
	content: '\e9a0';
}
.prism-mat-sharp.category:before {
	content: '\e9a1';
}
.prism-mat-sharp.compass_calibration:before {
	content: '\e9a2';
}
.prism-mat-sharp.departure_board:before {
	content: '\e9a3';
}
.prism-mat-sharp.directions_bike:before {
	content: '\e9a4';
}
.prism-mat-sharp.directions_boat:before {
	content: '\e9a5';
}
.prism-mat-sharp.directions_bus:before {
	content: '\e9a6';
}
.prism-mat-sharp.directions_car:before {
	content: '\e9a7';
}
.prism-mat-sharp.directions_railway:before {
	content: '\e9a8';
}
.prism-mat-sharp.directions_run:before {
	content: '\e9a9';
}
.prism-mat-sharp.directions_subway:before {
	content: '\e9aa';
}
.prism-mat-sharp.directions_transit:before {
	content: '\e9ab';
}
.prism-mat-sharp.directions_walk:before {
	content: '\e9ac';
}
.prism-mat-sharp.directions:before {
	content: '\e9ad';
}
.prism-mat-sharp.edit_attributes:before {
	content: '\e9ae';
}
.prism-mat-sharp.edit_location:before {
	content: '\e9af';
}
.prism-mat-sharp.ev_station:before {
	content: '\e9b0';
}
.prism-mat-sharp.fastfood:before {
	content: '\e9b1';
}
.prism-mat-sharp.flight:before {
	content: '\e9b2';
}
.prism-mat-sharp.hotel:before {
	content: '\e9b3';
}
.prism-mat-sharp.layers_clear:before {
	content: '\e9b4';
}
.prism-mat-sharp.layers:before {
	content: '\e9b5';
}
.prism-mat-sharp.local_activity:before {
	content: '\e9b6';
}
.prism-mat-sharp.local_airport:before {
	content: '\e9b7';
}
.prism-mat-sharp.local_atm:before {
	content: '\e9b8';
}
.prism-mat-sharp.local_bar:before {
	content: '\e9b9';
}
.prism-mat-sharp.local_cafe:before {
	content: '\e9ba';
}
.prism-mat-sharp.local_car_wash:before {
	content: '\e9bb';
}
.prism-mat-sharp.local_convenience_store:before {
	content: '\e9bc';
}
.prism-mat-sharp.local_dining:before {
	content: '\e9bd';
}
.prism-mat-sharp.local_drink:before {
	content: '\e9be';
}
.prism-mat-sharp.local_florist:before {
	content: '\e9bf';
}
.prism-mat-sharp.local_gas_station:before {
	content: '\e9c0';
}
.prism-mat-sharp.local_grocery_store:before {
	content: '\e9c1';
}
.prism-mat-sharp.local_hospital:before {
	content: '\e9c2';
}
.prism-mat-sharp.local_hotel:before {
	content: '\e9c3';
}
.prism-mat-sharp.local_laundry_service:before {
	content: '\e9c4';
}
.prism-mat-sharp.local_library:before {
	content: '\e9c5';
}
.prism-mat-sharp.local_mall:before {
	content: '\e9c6';
}
.prism-mat-sharp.local_movies:before {
	content: '\e9c7';
}
.prism-mat-sharp.local_offer:before {
	content: '\e9c8';
}
.prism-mat-sharp.local_parking:before {
	content: '\e9c9';
}
.prism-mat-sharp.local_pharmacy:before {
	content: '\e9ca';
}
.prism-mat-sharp.local_phone:before {
	content: '\e9cb';
}
.prism-mat-sharp.local_pizza:before {
	content: '\e9cc';
}
.prism-mat-sharp.local_play:before {
	content: '\e9cd';
}
.prism-mat-sharp.local_post_office:before {
	content: '\e9ce';
}
.prism-mat-sharp.local_printshop:before {
	content: '\e9cf';
}
.prism-mat-sharp.local_see:before {
	content: '\e9d0';
}
.prism-mat-sharp.local_shipping:before {
	content: '\e9d1';
}
.prism-mat-sharp.local_taxi:before {
	content: '\e9d2';
}
.prism-mat-sharp.map:before {
	content: '\e9d3';
}
.prism-mat-sharp.money:before {
	content: '\e9d4';
}
.prism-mat-sharp.my_location:before {
	content: '\e9d5';
}
.prism-mat-sharp.navigation:before {
	content: '\e9d6';
}
.prism-mat-sharp.near_me:before {
	content: '\e9d7';
}
.prism-mat-sharp.not_listed_location:before {
	content: '\e9d8';
}
.prism-mat-sharp.person_pin_circle:before {
	content: '\e9d9';
}
.prism-mat-sharp.person_pin:before {
	content: '\e9da';
}
.prism-mat-sharp.pin_drop:before {
	content: '\e9db';
}
.prism-mat-sharp.place:before {
	content: '\e9dc';
}
.prism-mat-sharp.rate_review:before {
	content: '\e9dd';
}
.prism-mat-sharp.restaurant_menu:before {
	content: '\e9de';
}
.prism-mat-sharp.restaurant:before {
	content: '\e9df';
}
.prism-mat-sharp.satellite:before {
	content: '\e9e0';
}
.prism-mat-sharp.store_mall_directory:before {
	content: '\e9e1';
}
.prism-mat-sharp.streetview:before {
	content: '\e9e2';
}
.prism-mat-sharp.subway:before {
	content: '\e9e3';
}
.prism-mat-sharp.terrain:before {
	content: '\e9e4';
}
.prism-mat-sharp.traffic:before {
	content: '\e9e5';
}
.prism-mat-sharp.train:before {
	content: '\e9e6';
}
.prism-mat-sharp.tram:before {
	content: '\e9e7';
}
.prism-mat-sharp.transfer_within_a_station:before {
	content: '\e9e8';
}
.prism-mat-sharp.transit_enterexit:before {
	content: '\e9e9';
}
.prism-mat-sharp.trip_origin:before {
	content: '\e9ea';
}
.prism-mat-sharp.zoom_out_map:before {
	content: '\e9eb';
}
.prism-mat-sharp.add_a_photo:before {
	content: '\e9ec';
}
.prism-mat-sharp.add_photo_alternate:before {
	content: '\e9ed';
}
.prism-mat-sharp.add_to_photos:before {
	content: '\e9ee';
}
.prism-mat-sharp.adjust:before {
	content: '\e9ef';
}
.prism-mat-sharp.assistant_photo:before {
	content: '\e9f0';
}
.prism-mat-sharp.assistant:before {
	content: '\e9f1';
}
.prism-mat-sharp.audiotrack:before {
	content: '\e9f2';
}
.prism-mat-sharp.blur_circular:before {
	content: '\e9f3';
}
.prism-mat-sharp.blur_linear:before {
	content: '\e9f4';
}
.prism-mat-sharp.blur_off:before {
	content: '\e9f5';
}
.prism-mat-sharp.blur_on:before {
	content: '\e9f6';
}
.prism-mat-sharp.brightness_1:before {
	content: '\e9f7';
}
.prism-mat-sharp.brightness_2:before {
	content: '\e9f8';
}
.prism-mat-sharp.brightness_3:before {
	content: '\e9f9';
}
.prism-mat-sharp.brightness_4:before {
	content: '\e9fa';
}
.prism-mat-sharp.brightness_5:before {
	content: '\e9fb';
}
.prism-mat-sharp.brightness_6:before {
	content: '\e9fc';
}
.prism-mat-sharp.brightness_7:before {
	content: '\e9fd';
}
.prism-mat-sharp.broken_image:before {
	content: '\e9fe';
}
.prism-mat-sharp.brush:before {
	content: '\e9ff';
}
.prism-mat-sharp.burst_mode:before {
	content: '\ea00';
}
.prism-mat-sharp.camera_alt:before {
	content: '\ea01';
}
.prism-mat-sharp.camera_front:before {
	content: '\ea02';
}
.prism-mat-sharp.camera_rear:before {
	content: '\ea03';
}
.prism-mat-sharp.camera_roll:before {
	content: '\ea04';
}
.prism-mat-sharp.camera:before {
	content: '\ea05';
}
.prism-mat-sharp.center_focus_strong:before {
	content: '\ea06';
}
.prism-mat-sharp.center_focus_weak:before {
	content: '\ea07';
}
.prism-mat-sharp.collections_bookmark:before {
	content: '\ea08';
}
.prism-mat-sharp.collections:before {
	content: '\ea09';
}
.prism-mat-sharp.color_lens:before {
	content: '\ea0a';
}
.prism-mat-sharp.colorize:before {
	content: '\ea0b';
}
.prism-mat-sharp.compare:before {
	content: '\ea0c';
}
.prism-mat-sharp.control_point_duplicate:before {
	content: '\ea0d';
}
.prism-mat-sharp.control_point:before {
	content: '\ea0e';
}
.prism-mat-sharp.crop_3_2:before {
	content: '\ea0f';
}
.prism-mat-sharp.crop_5_4:before {
	content: '\ea10';
}
.prism-mat-sharp.crop_7_5:before {
	content: '\ea11';
}
.prism-mat-sharp.crop_16_9:before {
	content: '\ea12';
}
.prism-mat-sharp.crop_din:before {
	content: '\ea13';
}
.prism-mat-sharp.crop_free:before {
	content: '\ea14';
}
.prism-mat-sharp.crop_landscape:before {
	content: '\ea15';
}
.prism-mat-sharp.crop_original:before {
	content: '\ea16';
}
.prism-mat-sharp.crop_portrait:before {
	content: '\ea17';
}
.prism-mat-sharp.crop_rotate:before {
	content: '\ea18';
}
.prism-mat-sharp.crop_square:before {
	content: '\ea19';
}
.prism-mat-sharp.crop:before {
	content: '\ea1a';
}
.prism-mat-sharp.dehaze:before {
	content: '\ea1b';
}
.prism-mat-sharp.details:before {
	content: '\ea1c';
}
.prism-mat-sharp.edit:before {
	content: '\ea1d';
}
.prism-mat-sharp.exposure_neg_1:before {
	content: '\ea1e';
}
.prism-mat-sharp.exposure_neg_2:before {
	content: '\ea1f';
}
.prism-mat-sharp.exposure_plus_1:before {
	content: '\ea20';
}
.prism-mat-sharp.exposure_plus_2:before {
	content: '\ea21';
}
.prism-mat-sharp.exposure_zero:before {
	content: '\ea22';
}
.prism-mat-sharp.exposure:before {
	content: '\ea23';
}
.prism-mat-sharp.filter_1:before {
	content: '\ea24';
}
.prism-mat-sharp.filter_2:before {
	content: '\ea25';
}
.prism-mat-sharp.filter_3:before {
	content: '\ea26';
}
.prism-mat-sharp.filter_4:before {
	content: '\ea27';
}
.prism-mat-sharp.filter_5:before {
	content: '\ea28';
}
.prism-mat-sharp.filter_6:before {
	content: '\ea29';
}
.prism-mat-sharp.filter_7:before {
	content: '\ea2a';
}
.prism-mat-sharp.filter_8:before {
	content: '\ea2b';
}
.prism-mat-sharp.filter_9_plus:before {
	content: '\ea2c';
}
.prism-mat-sharp.filter_9:before {
	content: '\ea2d';
}
.prism-mat-sharp.filter_b_and_w:before {
	content: '\ea2e';
}
.prism-mat-sharp.filter_center_focus:before {
	content: '\ea2f';
}
.prism-mat-sharp.filter_drama:before {
	content: '\ea30';
}
.prism-mat-sharp.filter_frames:before {
	content: '\ea31';
}
.prism-mat-sharp.filter_hdr:before {
	content: '\ea32';
}
.prism-mat-sharp.filter_none:before {
	content: '\ea33';
}
.prism-mat-sharp.filter_tilt_shift:before {
	content: '\ea34';
}
.prism-mat-sharp.filter_vintage:before {
	content: '\ea35';
}
.prism-mat-sharp.filter:before {
	content: '\ea36';
}
.prism-mat-sharp.flare:before {
	content: '\ea37';
}
.prism-mat-sharp.flash_auto:before {
	content: '\ea38';
}
.prism-mat-sharp.flash_off:before {
	content: '\ea39';
}
.prism-mat-sharp.flash_on:before {
	content: '\ea3a';
}
.prism-mat-sharp.flip:before {
	content: '\ea3b';
}
.prism-mat-sharp.gradient:before {
	content: '\ea3c';
}
.prism-mat-sharp.grain:before {
	content: '\ea3d';
}
.prism-mat-sharp.grid_off:before {
	content: '\ea3e';
}
.prism-mat-sharp.grid_on:before {
	content: '\ea3f';
}
.prism-mat-sharp.hdr_off:before {
	content: '\ea40';
}
.prism-mat-sharp.hdr_on:before {
	content: '\ea41';
}
.prism-mat-sharp.hdr_strong:before {
	content: '\ea42';
}
.prism-mat-sharp.hdr_weak:before {
	content: '\ea43';
}
.prism-mat-sharp.healing:before {
	content: '\ea44';
}
.prism-mat-sharp.image_aspect_ratio:before {
	content: '\ea45';
}
.prism-mat-sharp.image_search:before {
	content: '\ea46';
}
.prism-mat-sharp.image:before {
	content: '\ea47';
}
.prism-mat-sharp.iso:before {
	content: '\ea48';
}
.prism-mat-sharp.landscape:before {
	content: '\ea49';
}
.prism-mat-sharp.leak_add:before {
	content: '\ea4a';
}
.prism-mat-sharp.leak_remove:before {
	content: '\ea4b';
}
.prism-mat-sharp.lens:before {
	content: '\ea4c';
}
.prism-mat-sharp.linked_camera:before {
	content: '\ea4d';
}
.prism-mat-sharp.looks_3:before {
	content: '\ea4e';
}
.prism-mat-sharp.looks_4:before {
	content: '\ea4f';
}
.prism-mat-sharp.looks_5:before {
	content: '\ea50';
}
.prism-mat-sharp.looks_6:before {
	content: '\ea51';
}
.prism-mat-sharp.looks_one:before {
	content: '\ea52';
}
.prism-mat-sharp.looks_two:before {
	content: '\ea53';
}
.prism-mat-sharp.looks:before {
	content: '\ea54';
}
.prism-mat-sharp.loupe:before {
	content: '\ea55';
}
.prism-mat-sharp.monochrome_photos:before {
	content: '\ea56';
}
.prism-mat-sharp.movie_creation:before {
	content: '\ea57';
}
.prism-mat-sharp.movie_filter:before {
	content: '\ea58';
}
.prism-mat-sharp.music_note:before {
	content: '\ea59';
}
.prism-mat-sharp.music_off:before {
	content: '\ea5a';
}
.prism-mat-sharp.nature_people:before {
	content: '\ea5b';
}
.prism-mat-sharp.nature:before {
	content: '\ea5c';
}
.prism-mat-sharp.navigate_before:before {
	content: '\ea5d';
}
.prism-mat-sharp.navigate_next:before {
	content: '\ea5e';
}
.prism-mat-sharp.palette:before {
	content: '\ea5f';
}
.prism-mat-sharp.panorama_fish_eye:before {
	content: '\ea60';
}
.prism-mat-sharp.panorama_horizontal:before {
	content: '\ea61';
}
.prism-mat-sharp.panorama_vertical:before {
	content: '\ea62';
}
.prism-mat-sharp.panorama_wide_angle:before {
	content: '\ea63';
}
.prism-mat-sharp.panorama:before {
	content: '\ea64';
}
.prism-mat-sharp.photo_album:before {
	content: '\ea65';
}
.prism-mat-sharp.photo_camera:before {
	content: '\ea66';
}
.prism-mat-sharp.photo_filter:before {
	content: '\ea67';
}
.prism-mat-sharp.photo_library:before {
	content: '\ea68';
}
.prism-mat-sharp.photo_size_select_actual:before {
	content: '\ea69';
}
.prism-mat-sharp.photo_size_select_large:before {
	content: '\ea6a';
}
.prism-mat-sharp.photo_size_select_small:before {
	content: '\ea6b';
}
.prism-mat-sharp.photo:before {
	content: '\ea6c';
}
.prism-mat-sharp.picture_as_pdf:before {
	content: '\ea6d';
}
.prism-mat-sharp.portrait:before {
	content: '\ea6e';
}
.prism-mat-sharp.remove_red_eye:before {
	content: '\ea6f';
}
.prism-mat-sharp.rotate_90_degrees_ccw:before {
	content: '\ea70';
}
.prism-mat-sharp.rotate_left:before {
	content: '\ea71';
}
.prism-mat-sharp.rotate_right:before {
	content: '\ea72';
}
.prism-mat-sharp.shutter_speed:before {
	content: '\ea73';
}
.prism-mat-sharp.slideshow:before {
	content: '\ea74';
}
.prism-mat-sharp.straighten:before {
	content: '\ea75';
}
.prism-mat-sharp.style:before {
	content: '\ea76';
}
.prism-mat-sharp.switch_camera:before {
	content: '\ea77';
}
.prism-mat-sharp.switch_video:before {
	content: '\ea78';
}
.prism-mat-sharp.tag_faces:before {
	content: '\ea79';
}
.prism-mat-sharp.texture:before {
	content: '\ea7a';
}
.prism-mat-sharp.timelapse:before {
	content: '\ea7b';
}
.prism-mat-sharp.timer_3:before {
	content: '\ea7c';
}
.prism-mat-sharp.timer_10:before {
	content: '\ea7d';
}
.prism-mat-sharp.timer_off:before {
	content: '\ea7e';
}
.prism-mat-sharp.timer:before {
	content: '\ea7f';
}
.prism-mat-sharp.tonality:before {
	content: '\ea80';
}
.prism-mat-sharp.transform:before {
	content: '\ea81';
}
.prism-mat-sharp.tune:before {
	content: '\ea82';
}
.prism-mat-sharp.view_comfy:before {
	content: '\ea83';
}
.prism-mat-sharp.view_compact:before {
	content: '\ea84';
}
.prism-mat-sharp.vignette:before {
	content: '\ea85';
}
.prism-mat-sharp.wb_auto:before {
	content: '\ea86';
}
.prism-mat-sharp.wb_cloudy:before {
	content: '\ea87';
}
.prism-mat-sharp.wb_incandescent:before {
	content: '\ea88';
}
.prism-mat-sharp.wb_iridescent:before {
	content: '\ea89';
}
.prism-mat-sharp.wb_sunny:before {
	content: '\ea8a';
}
.prism-mat-sharp.cast_connected:before {
	content: '\ea8b';
}
.prism-mat-sharp.cast_for_education:before {
	content: '\ea8c';
}
.prism-mat-sharp.cast:before {
	content: '\ea8d';
}
.prism-mat-sharp.computer:before {
	content: '\ea8e';
}
.prism-mat-sharp.desktop_mac:before {
	content: '\ea8f';
}
.prism-mat-sharp.desktop_windows:before {
	content: '\ea90';
}
.prism-mat-sharp.developer_board:before {
	content: '\ea91';
}
.prism-mat-sharp.device_hub:before {
	content: '\ea92';
}
.prism-mat-sharp.device_unknown:before {
	content: '\ea93';
}
.prism-mat-sharp.devices_other:before {
	content: '\ea94';
}
.prism-mat-sharp.dock:before {
	content: '\ea95';
}
.prism-mat-sharp.gamepad:before {
	content: '\ea96';
}
.prism-mat-sharp.headset_mic:before {
	content: '\ea97';
}
.prism-mat-sharp.headset:before {
	content: '\ea98';
}
.prism-mat-sharp.keyboard_arrow_down:before {
	content: '\ea99';
}
.prism-mat-sharp.keyboard_arrow_left:before {
	content: '\ea9a';
}
.prism-mat-sharp.keyboard_arrow_right:before {
	content: '\ea9b';
}
.prism-mat-sharp.keyboard_arrow_up:before {
	content: '\ea9c';
}
.prism-mat-sharp.keyboard_backspace:before {
	content: '\ea9d';
}
.prism-mat-sharp.keyboard_capslock:before {
	content: '\ea9e';
}
.prism-mat-sharp.keyboard_hide:before {
	content: '\ea9f';
}
.prism-mat-sharp.keyboard_return:before {
	content: '\eaa0';
}
.prism-mat-sharp.keyboard_tab:before {
	content: '\eaa1';
}
.prism-mat-sharp.keyboard_voice:before {
	content: '\eaa2';
}
.prism-mat-sharp.keyboard:before {
	content: '\eaa3';
}
.prism-mat-sharp.laptop_chromebook:before {
	content: '\eaa4';
}
.prism-mat-sharp.laptop_mac:before {
	content: '\eaa5';
}
.prism-mat-sharp.laptop_windows:before {
	content: '\eaa6';
}
.prism-mat-sharp.laptop:before {
	content: '\eaa7';
}
.prism-mat-sharp.memory:before {
	content: '\eaa8';
}
.prism-mat-sharp.mouse:before {
	content: '\eaa9';
}
.prism-mat-sharp.phone_android:before {
	content: '\eaaa';
}
.prism-mat-sharp.phone_iphone:before {
	content: '\eaab';
}
.prism-mat-sharp.phonelink_off:before {
	content: '\eaac';
}
.prism-mat-sharp.phonelink:before {
	content: '\eaad';
}
.prism-mat-sharp.power_input:before {
	content: '\eaae';
}
.prism-mat-sharp.router:before {
	content: '\eaaf';
}
.prism-mat-sharp.scanner:before {
	content: '\eab0';
}
.prism-mat-sharp.security:before {
	content: '\eab1';
}
.prism-mat-sharp.sim_card:before {
	content: '\eab2';
}
.prism-mat-sharp.smartphone:before {
	content: '\eab3';
}
.prism-mat-sharp.speaker_group:before {
	content: '\eab4';
}
.prism-mat-sharp.speaker:before {
	content: '\eab5';
}
.prism-mat-sharp.tablet_android:before {
	content: '\eab6';
}
.prism-mat-sharp.tablet_mac:before {
	content: '\eab7';
}
.prism-mat-sharp.tablet:before {
	content: '\eab8';
}
.prism-mat-sharp.toys:before {
	content: '\eab9';
}
.prism-mat-sharp.tv:before {
	content: '\eaba';
}
.prism-mat-sharp.videogame_asset:before {
	content: '\eabb';
}
.prism-mat-sharp.watch:before {
	content: '\eabc';
}
.prism-mat-sharp.attachment:before {
	content: '\eabd';
}
.prism-mat-sharp.cloud_circle:before {
	content: '\eabe';
}
.prism-mat-sharp.cloud_done:before {
	content: '\eabf';
}
.prism-mat-sharp.cloud_download:before {
	content: '\eac0';
}
.prism-mat-sharp.cloud_off:before {
	content: '\eac1';
}
.prism-mat-sharp.cloud_queue:before {
	content: '\eac2';
}
.prism-mat-sharp.cloud_upload:before {
	content: '\eac3';
}
.prism-mat-sharp.cloud:before {
	content: '\eac4';
}
.prism-mat-sharp.create_new_folder:before {
	content: '\eac5';
}
.prism-mat-sharp.download_done:before {
	content: '\eac6';
}
.prism-mat-sharp.download:before {
	content: '\eac7';
}
.prism-mat-sharp.folder_open:before {
	content: '\eac8';
}
.prism-mat-sharp.folder_shared:before {
	content: '\eac9';
}
.prism-mat-sharp.folder:before {
	content: '\eaca';
}
.prism-mat-sharp.upload:before {
	content: '\eacb';
}
.prism-mat-sharp.add_comment:before {
	content: '\eacc';
}
.prism-mat-sharp.attach_file:before {
	content: '\eacd';
}
.prism-mat-sharp.attach_money:before {
	content: '\eace';
}
.prism-mat-sharp.bar_chart:before {
	content: '\eacf';
}
.prism-mat-sharp.border_all:before {
	content: '\ead0';
}
.prism-mat-sharp.border_bottom:before {
	content: '\ead1';
}
.prism-mat-sharp.border_clear:before {
	content: '\ead2';
}
.prism-mat-sharp.border_color:before {
	content: '\ead3';
}
.prism-mat-sharp.border_horizontal:before {
	content: '\ead4';
}
.prism-mat-sharp.border_inner:before {
	content: '\ead5';
}
.prism-mat-sharp.border_left:before {
	content: '\ead6';
}
.prism-mat-sharp.border_outer:before {
	content: '\ead7';
}
.prism-mat-sharp.border_right:before {
	content: '\ead8';
}
.prism-mat-sharp.border_style:before {
	content: '\ead9';
}
.prism-mat-sharp.border_top:before {
	content: '\eada';
}
.prism-mat-sharp.border_vertical:before {
	content: '\eadb';
}
.prism-mat-sharp.bubble_chart:before {
	content: '\eadc';
}
.prism-mat-sharp.drag_handle:before {
	content: '\eadd';
}
.prism-mat-sharp.format_align_center:before {
	content: '\eade';
}
.prism-mat-sharp.format_align_justify:before {
	content: '\eadf';
}
.prism-mat-sharp.format_align_left:before {
	content: '\eae0';
}
.prism-mat-sharp.format_align_right:before {
	content: '\eae1';
}
.prism-mat-sharp.format_bold:before {
	content: '\eae2';
}
.prism-mat-sharp.format_clear:before {
	content: '\eae3';
}
.prism-mat-sharp.format_color_fill:before {
	content: '\eae4';
}
.prism-mat-sharp.format_color_reset:before {
	content: '\eae5';
}
.prism-mat-sharp.format_color_text:before {
	content: '\eae6';
}
.prism-mat-sharp.format_indent_decrease:before {
	content: '\eae7';
}
.prism-mat-sharp.format_indent_increase:before {
	content: '\eae8';
}
.prism-mat-sharp.format_italic:before {
	content: '\eae9';
}
.prism-mat-sharp.format_line_spacing:before {
	content: '\eaea';
}
.prism-mat-sharp.format_list_bulleted:before {
	content: '\eaeb';
}
.prism-mat-sharp.format_list_numbered_rtl:before {
	content: '\eaec';
}
.prism-mat-sharp.format_list_numbered:before {
	content: '\eaed';
}
.prism-mat-sharp.format_paint:before {
	content: '\eaee';
}
.prism-mat-sharp.format_quote:before {
	content: '\eaef';
}
.prism-mat-sharp.format_shapes:before {
	content: '\eaf0';
}
.prism-mat-sharp.format_size:before {
	content: '\eaf1';
}
.prism-mat-sharp.format_strikethrough:before {
	content: '\eaf2';
}
.prism-mat-sharp.format_textdirection_l_to_r:before {
	content: '\eaf3';
}
.prism-mat-sharp.format_textdirection_r_to_l:before {
	content: '\eaf4';
}
.prism-mat-sharp.format_underlined:before {
	content: '\eaf5';
}
.prism-mat-sharp.functions:before {
	content: '\eaf6';
}
.prism-mat-sharp.highlight:before {
	content: '\eaf7';
}
.prism-mat-sharp.insert_chart_outlined:before {
	content: '\eaf8';
}
.prism-mat-sharp.insert_chart:before {
	content: '\eaf9';
}
.prism-mat-sharp.insert_comment:before {
	content: '\eafa';
}
.prism-mat-sharp.insert_drive_file:before {
	content: '\eafb';
}
.prism-mat-sharp.insert_emoticon:before {
	content: '\eafc';
}
.prism-mat-sharp.insert_invitation:before {
	content: '\eafd';
}
.prism-mat-sharp.insert_link:before {
	content: '\eafe';
}
.prism-mat-sharp.insert_photo:before {
	content: '\eaff';
}
.prism-mat-sharp.linear_scale:before {
	content: '\eb00';
}
.prism-mat-sharp.merge_type:before {
	content: '\eb01';
}
.prism-mat-sharp.mode_comment:before {
	content: '\eb02';
}
.prism-mat-sharp.mode:before {
	content: '\eb03';
}
.prism-mat-sharp.monetization_on:before {
	content: '\eb04';
}
.prism-mat-sharp.money_off_csred:before {
	content: '\eb05';
}
.prism-mat-sharp.money_off:before {
	content: '\eb06';
}
.prism-mat-sharp.multiline_chart:before {
	content: '\eb07';
}
.prism-mat-sharp.notes:before {
	content: '\eb08';
}
.prism-mat-sharp.pie_chart_outline:before {
	content: '\eb09';
}
.prism-mat-sharp.pie_chart:before {
	content: '\eb0a';
}
.prism-mat-sharp.publish:before {
	content: '\eb0b';
}
.prism-mat-sharp.scatter_plot:before {
	content: '\eb0c';
}
.prism-mat-sharp.score:before {
	content: '\eb0d';
}
.prism-mat-sharp.short_text:before {
	content: '\eb0e';
}
.prism-mat-sharp.show_chart:before {
	content: '\eb0f';
}
.prism-mat-sharp.space_bar:before {
	content: '\eb10';
}
.prism-mat-sharp.strikethrough_s:before {
	content: '\eb11';
}
.prism-mat-sharp.table_chart:before {
	content: '\eb12';
}
.prism-mat-sharp.text_fields:before {
	content: '\eb13';
}
.prism-mat-sharp.title:before {
	content: '\eb14';
}
.prism-mat-sharp.vertical_align_bottom:before {
	content: '\eb15';
}
.prism-mat-sharp.vertical_align_center:before {
	content: '\eb16';
}
.prism-mat-sharp.vertical_align_top:before {
	content: '\eb17';
}
.prism-mat-sharp.wrap_text:before {
	content: '\eb18';
}
.prism-mat-sharp.access_alarm:before {
	content: '\eb19';
}
.prism-mat-sharp.access_alarms:before {
	content: '\eb1a';
}
.prism-mat-sharp.access_time:before {
	content: '\eb1b';
}
.prism-mat-sharp.add_alarm:before {
	content: '\eb1c';
}
.prism-mat-sharp.add_to_home_screen:before {
	content: '\eb1d';
}
.prism-mat-sharp.airplanemode_active:before {
	content: '\eb1e';
}
.prism-mat-sharp.airplanemode_inactive:before {
	content: '\eb1f';
}
.prism-mat-sharp.battery_alert:before {
	content: '\eb20';
}
.prism-mat-sharp.battery_charging_full:before {
	content: '\eb21';
}
.prism-mat-sharp.battery_full:before {
	content: '\eb22';
}
.prism-mat-sharp.battery_std:before {
	content: '\eb23';
}
.prism-mat-sharp.battery_unknown:before {
	content: '\eb24';
}
.prism-mat-sharp.bluetooth_connected:before {
	content: '\eb25';
}
.prism-mat-sharp.bluetooth_disabled:before {
	content: '\eb26';
}
.prism-mat-sharp.bluetooth_searching:before {
	content: '\eb27';
}
.prism-mat-sharp.bluetooth:before {
	content: '\eb28';
}
.prism-mat-sharp.brightness_auto:before {
	content: '\eb29';
}
.prism-mat-sharp.brightness_high:before {
	content: '\eb2a';
}
.prism-mat-sharp.brightness_low:before {
	content: '\eb2b';
}
.prism-mat-sharp.brightness_medium:before {
	content: '\eb2c';
}
.prism-mat-sharp.data_usage:before {
	content: '\eb2d';
}
.prism-mat-sharp.developer_mode:before {
	content: '\eb2e';
}
.prism-mat-sharp.devices:before {
	content: '\eb2f';
}
.prism-mat-sharp.dvr:before {
	content: '\eb30';
}
.prism-mat-sharp.gps_fixed:before {
	content: '\eb31';
}
.prism-mat-sharp.gps_not_fixed:before {
	content: '\eb32';
}
.prism-mat-sharp.gps_off:before {
	content: '\eb33';
}
.prism-mat-sharp.graphic_eq:before {
	content: '\eb34';
}
.prism-mat-sharp.location_disabled:before {
	content: '\eb35';
}
.prism-mat-sharp.location_searching:before {
	content: '\eb36';
}
.prism-mat-sharp.mobile_friendly:before {
	content: '\eb37';
}
.prism-mat-sharp.mobile_off:before {
	content: '\eb38';
}
.prism-mat-sharp.network_cell:before {
	content: '\eb39';
}
.prism-mat-sharp.network_wifi:before {
	content: '\eb3a';
}
.prism-mat-sharp.nfc:before {
	content: '\eb3b';
}
.prism-mat-sharp.screen_lock_landscape:before {
	content: '\eb3c';
}
.prism-mat-sharp.screen_lock_portrait:before {
	content: '\eb3d';
}
.prism-mat-sharp.screen_lock_rotation:before {
	content: '\eb3e';
}
.prism-mat-sharp.screen_rotation:before {
	content: '\eb3f';
}
.prism-mat-sharp.sd_storage:before {
	content: '\eb40';
}
.prism-mat-sharp.settings_system_daydream:before {
	content: '\eb41';
}
.prism-mat-sharp.signal_cellular_alt:before {
	content: '\eb42';
}
.prism-mat-sharp.signal_cellular_connected_no_internet_4_bar:before {
	content: '\eb43';
}
.prism-mat-sharp.signal_cellular_no_sim:before {
	content: '\eb44';
}
.prism-mat-sharp.signal_cellular_null:before {
	content: '\eb45';
}
.prism-mat-sharp.signal_cellular_off:before {
	content: '\eb46';
}
.prism-mat-sharp.signal_wifi_off:before {
	content: '\eb47';
}
.prism-mat-sharp.signal_wifi_statusbar_connected_no_internet:before {
	content: '\eb48';
}
.prism-mat-sharp.signal_wifi_statusbar_not_connected:before {
	content: '\eb49';
}
.prism-mat-sharp.signal_wifi_statusbar_null:before {
	content: '\eb4a';
}
.prism-mat-sharp.storage:before {
	content: '\eb4b';
}
.prism-mat-sharp.thermostat:before {
	content: '\eb4c';
}
.prism-mat-sharp.usb:before {
	content: '\eb4d';
}
.prism-mat-sharp.wallpaper:before {
	content: '\eb4e';
}
.prism-mat-sharp.widgets:before {
	content: '\eb4f';
}
.prism-mat-sharp.wifi_lock:before {
	content: '\eb50';
}
.prism-mat-sharp.wifi_tethering:before {
	content: '\eb51';
}
.prism-mat-sharp.add_box:before {
	content: '\eb52';
}
.prism-mat-sharp.add_circle_outline:before {
	content: '\eb53';
}
.prism-mat-sharp.add_circle:before {
	content: '\eb54';
}
.prism-mat-sharp.add:before {
	content: '\eb55';
}
.prism-mat-sharp.archive:before {
	content: '\eb56';
}
.prism-mat-sharp.attribution:before {
	content: '\eb57';
}
.prism-mat-sharp.backspace:before {
	content: '\eb58';
}
.prism-mat-sharp.ballot:before {
	content: '\eb59';
}
.prism-mat-sharp.block:before {
	content: '\eb5a';
}
.prism-mat-sharp.clear:before {
	content: '\eb5b';
}
.prism-mat-sharp.copy:before {
	content: '\eb5c';
}
.prism-mat-sharp.create:before {
	content: '\eb5d';
}
.prism-mat-sharp.cut:before {
	content: '\eb5e';
}
.prism-mat-sharp.delete_sweep:before {
	content: '\eb5f';
}
.prism-mat-sharp.drafts:before {
	content: '\eb60';
}
.prism-mat-sharp.file_copy:before {
	content: '\eb61';
}
.prism-mat-sharp.filter_list:before {
	content: '\eb62';
}
.prism-mat-sharp.flag:before {
	content: '\eb63';
}
.prism-mat-sharp.font_download:before {
	content: '\eb64';
}
.prism-mat-sharp.forward:before {
	content: '\eb65';
}
.prism-mat-sharp.gesture:before {
	content: '\eb66';
}
.prism-mat-sharp.how_to_reg:before {
	content: '\eb67';
}
.prism-mat-sharp.how_to_vote:before {
	content: '\eb68';
}
.prism-mat-sharp.inbox:before {
	content: '\eb69';
}
.prism-mat-sharp.link_off:before {
	content: '\eb6a';
}
.prism-mat-sharp.link:before {
	content: '\eb6b';
}
.prism-mat-sharp.low_priority:before {
	content: '\eb6c';
}
.prism-mat-sharp.mail:before {
	content: '\eb6d';
}
.prism-mat-sharp.markunread:before {
	content: '\eb6e';
}
.prism-mat-sharp.move_to_inbox:before {
	content: '\eb6f';
}
.prism-mat-sharp.next_week:before {
	content: '\eb70';
}
.prism-mat-sharp.outlined_flag:before {
	content: '\eb71';
}
.prism-mat-sharp.paste:before {
	content: '\eb72';
}
.prism-mat-sharp.redo:before {
	content: '\eb73';
}
.prism-mat-sharp.remove_circle_outline:before {
	content: '\eb74';
}
.prism-mat-sharp.remove_circle:before {
	content: '\eb75';
}
.prism-mat-sharp.remove:before {
	content: '\eb76';
}
.prism-mat-sharp.reply_all:before {
	content: '\eb77';
}
.prism-mat-sharp.reply:before {
	content: '\eb78';
}
.prism-mat-sharp.report_gmailerrorred:before {
	content: '\eb79';
}
.prism-mat-sharp.report_off:before {
	content: '\eb7a';
}
.prism-mat-sharp.report:before {
	content: '\eb7b';
}
.prism-mat-sharp.save_alt:before {
	content: '\eb7c';
}
.prism-mat-sharp.save:before {
	content: '\eb7d';
}
.prism-mat-sharp.select_all:before {
	content: '\eb7e';
}
.prism-mat-sharp.send:before {
	content: '\eb7f';
}
.prism-mat-sharp.sort:before {
	content: '\eb80';
}
.prism-mat-sharp.text_format:before {
	content: '\eb81';
}
.prism-mat-sharp.unarchive:before {
	content: '\eb82';
}
.prism-mat-sharp.undo:before {
	content: '\eb83';
}
.prism-mat-sharp.waves:before {
	content: '\eb84';
}
.prism-mat-sharp.weekend:before {
	content: '\eb85';
}
.prism-mat-sharp.where_to_vote:before {
	content: '\eb86';
}
.prism-mat-sharp.add_call:before {
	content: '\eb87';
}
.prism-mat-sharp.alternate_email:before {
	content: '\eb88';
}
.prism-mat-sharp.business:before {
	content: '\eb89';
}
.prism-mat-sharp.call_end:before {
	content: '\eb8a';
}
.prism-mat-sharp.call_made:before {
	content: '\eb8b';
}
.prism-mat-sharp.call_merge:before {
	content: '\eb8c';
}
.prism-mat-sharp.call_missed_outgoing:before {
	content: '\eb8d';
}
.prism-mat-sharp.call_missed:before {
	content: '\eb8e';
}
.prism-mat-sharp.call_received:before {
	content: '\eb8f';
}
.prism-mat-sharp.call_split:before {
	content: '\eb90';
}
.prism-mat-sharp.call:before {
	content: '\eb91';
}
.prism-mat-sharp.cancel_presentation:before {
	content: '\eb92';
}
.prism-mat-sharp.cell_wifi:before {
	content: '\eb93';
}
.prism-mat-sharp.chat_bubble_outline:before {
	content: '\eb94';
}
.prism-mat-sharp.chat_bubble:before {
	content: '\eb95';
}
.prism-mat-sharp.chat:before {
	content: '\eb96';
}
.prism-mat-sharp.clear_all:before {
	content: '\eb97';
}
.prism-mat-sharp.comment:before {
	content: '\eb98';
}
.prism-mat-sharp.contact_mail:before {
	content: '\eb99';
}
.prism-mat-sharp.contact_phone:before {
	content: '\eb9a';
}
.prism-mat-sharp.contacts:before {
	content: '\eb9b';
}
.prism-mat-sharp.desktop_access_disabled:before {
	content: '\eb9c';
}
.prism-mat-sharp.dialer_sip:before {
	content: '\eb9d';
}
.prism-mat-sharp.dialpad:before {
	content: '\eb9e';
}
.prism-mat-sharp.domain_disabled:before {
	content: '\eb9f';
}
.prism-mat-sharp.duo:before {
	content: '\eba0';
}
.prism-mat-sharp.email:before {
	content: '\eba1';
}
.prism-mat-sharp.forum:before {
	content: '\eba2';
}
.prism-mat-sharp.import_contacts:before {
	content: '\eba3';
}
.prism-mat-sharp.import_export:before {
	content: '\eba4';
}
.prism-mat-sharp.invert_colors_off:before {
	content: '\eba5';
}
.prism-mat-sharp.list_alt:before {
	content: '\eba6';
}
.prism-mat-sharp.live_help:before {
	content: '\eba7';
}
.prism-mat-sharp.location_off:before {
	content: '\eba8';
}
.prism-mat-sharp.location_on:before {
	content: '\eba9';
}
.prism-mat-sharp.mail_outline:before {
	content: '\ebaa';
}
.prism-mat-sharp.message:before {
	content: '\ebab';
}
.prism-mat-sharp.mobile_screen_share:before {
	content: '\ebac';
}
.prism-mat-sharp.no_sim:before {
	content: '\ebad';
}
.prism-mat-sharp.pause_presentation:before {
	content: '\ebae';
}
.prism-mat-sharp.person_add_disabled:before {
	content: '\ebaf';
}
.prism-mat-sharp.phone:before {
	content: '\ebb0';
}
.prism-mat-sharp.phonelink_erase:before {
	content: '\ebb1';
}
.prism-mat-sharp.phonelink_lock:before {
	content: '\ebb2';
}
.prism-mat-sharp.phonelink_ring:before {
	content: '\ebb3';
}
.prism-mat-sharp.phonelink_setup:before {
	content: '\ebb4';
}
.prism-mat-sharp.portable_wifi_off:before {
	content: '\ebb5';
}
.prism-mat-sharp.present_to_all:before {
	content: '\ebb6';
}
.prism-mat-sharp.print_disabled:before {
	content: '\ebb7';
}
.prism-mat-sharp.ring_volume:before {
	content: '\ebb8';
}
.prism-mat-sharp.rss_feed:before {
	content: '\ebb9';
}
.prism-mat-sharp.screen_share:before {
	content: '\ebba';
}
.prism-mat-sharp.sentiment_satisfied_alt:before {
	content: '\ebbb';
}
.prism-mat-sharp.speaker_phone:before {
	content: '\ebbc';
}
.prism-mat-sharp.stay_current_landscape:before {
	content: '\ebbd';
}
.prism-mat-sharp.stay_current_portrait:before {
	content: '\ebbe';
}
.prism-mat-sharp.stay_primary_landscape:before {
	content: '\ebbf';
}
.prism-mat-sharp.stay_primary_portrait:before {
	content: '\ebc0';
}
.prism-mat-sharp.stop_screen_share:before {
	content: '\ebc1';
}
.prism-mat-sharp.swap_calls:before {
	content: '\ebc2';
}
.prism-mat-sharp.textsms:before {
	content: '\ebc3';
}
.prism-mat-sharp.unsubscribe:before {
	content: '\ebc4';
}
.prism-mat-sharp.voicemail:before {
	content: '\ebc5';
}
.prism-mat-sharp.vpn_key:before {
	content: '\ebc6';
}
.prism-mat-sharp.k:before {
	content: '\ebc7';
}
.prism-mat-sharp.add_to_queue:before {
	content: '\ebc8';
}
.prism-mat-sharp.airplay:before {
	content: '\ebc9';
}
.prism-mat-sharp.album:before {
	content: '\ebca';
}
.prism-mat-sharp.art_track:before {
	content: '\ebcb';
}
.prism-mat-sharp.branding_watermark:before {
	content: '\ebcc';
}
.prism-mat-sharp.call_to_action:before {
	content: '\ebcd';
}
.prism-mat-sharp.closed_caption:before {
	content: '\ebce';
}
.prism-mat-sharp.control_camera:before {
	content: '\ebcf';
}
.prism-mat-sharp.equalizer:before {
	content: '\ebd0';
}
.prism-mat-sharp.explicit:before {
	content: '\ebd1';
}
.prism-mat-sharp.fast_forward:before {
	content: '\ebd2';
}
.prism-mat-sharp.fast_rewind:before {
	content: '\ebd3';
}
.prism-mat-sharp.featured_play_list:before {
	content: '\ebd4';
}
.prism-mat-sharp.featured_video:before {
	content: '\ebd5';
}
.prism-mat-sharp.fiber_dvr:before {
	content: '\ebd6';
}
.prism-mat-sharp.fiber_manual_record:before {
	content: '\ebd7';
}
.prism-mat-sharp.fiber_new:before {
	content: '\ebd8';
}
.prism-mat-sharp.fiber_pin:before {
	content: '\ebd9';
}
.prism-mat-sharp.fiber_smart_record:before {
	content: '\ebda';
}
.prism-mat-sharp.forward_5:before {
	content: '\ebdb';
}
.prism-mat-sharp.forward_10:before {
	content: '\ebdc';
}
.prism-mat-sharp.forward_30:before {
	content: '\ebdd';
}
.prism-mat-sharp.games:before {
	content: '\ebde';
}
.prism-mat-sharp.hd:before {
	content: '\ebdf';
}
.prism-mat-sharp.hearing:before {
	content: '\ebe0';
}
.prism-mat-sharp.high_quality:before {
	content: '\ebe1';
}
.prism-mat-sharp.library_add:before {
	content: '\ebe2';
}
.prism-mat-sharp.library_books:before {
	content: '\ebe3';
}
.prism-mat-sharp.library_music:before {
	content: '\ebe4';
}
.prism-mat-sharp.loop:before {
	content: '\ebe5';
}
.prism-mat-sharp.mic_none:before {
	content: '\ebe6';
}
.prism-mat-sharp.mic_off:before {
	content: '\ebe7';
}
.prism-mat-sharp.mic:before {
	content: '\ebe8';
}
.prism-mat-sharp.missed_video_call:before {
	content: '\ebe9';
}
.prism-mat-sharp.movie:before {
	content: '\ebea';
}
.prism-mat-sharp.music_video:before {
	content: '\ebeb';
}
.prism-mat-sharp.new_releases:before {
	content: '\ebec';
}
.prism-mat-sharp.not_interested:before {
	content: '\ebed';
}
.prism-mat-sharp.note:before {
	content: '\ebee';
}
.prism-mat-sharp.pause_circle_filled:before {
	content: '\ebef';
}
.prism-mat-sharp.pause_circle_outline:before {
	content: '\ebf0';
}
.prism-mat-sharp.pause:before {
	content: '\ebf1';
}
.prism-mat-sharp.play_arrow:before {
	content: '\ebf2';
}
.prism-mat-sharp.play_circle_filled_white:before {
	content: '\ebf3';
}
.prism-mat-sharp.play_circle_filled:before {
	content: '\ebf4';
}
.prism-mat-sharp.play_circle_outline:before {
	content: '\ebf5';
}
.prism-mat-sharp.playlist_add_check:before {
	content: '\ebf6';
}
.prism-mat-sharp.playlist_add:before {
	content: '\ebf7';
}
.prism-mat-sharp.playlist_play:before {
	content: '\ebf8';
}
.prism-mat-sharp.queue_music:before {
	content: '\ebf9';
}
.prism-mat-sharp.queue_play_next:before {
	content: '\ebfa';
}
.prism-mat-sharp.queue:before {
	content: '\ebfb';
}
.prism-mat-sharp.radio:before {
	content: '\ebfc';
}
.prism-mat-sharp.recent_actors:before {
	content: '\ebfd';
}
.prism-mat-sharp.remove_from_queue:before {
	content: '\ebfe';
}
.prism-mat-sharp.repeat_one:before {
	content: '\ebff';
}
.prism-mat-sharp.repeat:before {
	content: '\ec00';
}
.prism-mat-sharp.replay_5:before {
	content: '\ec01';
}
.prism-mat-sharp.replay_10:before {
	content: '\ec02';
}
.prism-mat-sharp.replay_30:before {
	content: '\ec03';
}
.prism-mat-sharp.replay:before {
	content: '\ec04';
}
.prism-mat-sharp.shuffle:before {
	content: '\ec05';
}
.prism-mat-sharp.skip_next:before {
	content: '\ec06';
}
.prism-mat-sharp.skip_previous:before {
	content: '\ec07';
}
.prism-mat-sharp.slow_motion_video:before {
	content: '\ec08';
}
.prism-mat-sharp.snooze:before {
	content: '\ec09';
}
.prism-mat-sharp.sort_by_alpha:before {
	content: '\ec0a';
}
.prism-mat-sharp.stop:before {
	content: '\ec0b';
}
.prism-mat-sharp.subscriptions:before {
	content: '\ec0c';
}
.prism-mat-sharp.subtitles:before {
	content: '\ec0d';
}
.prism-mat-sharp.surround_sound:before {
	content: '\ec0e';
}
.prism-mat-sharp.timer1:before {
	content: '\ec0f';
}
.prism-mat-sharp.video_call:before {
	content: '\ec10';
}
.prism-mat-sharp.video_label:before {
	content: '\ec11';
}
.prism-mat-sharp.video_library:before {
	content: '\ec12';
}
.prism-mat-sharp.videocam_off:before {
	content: '\ec13';
}
.prism-mat-sharp.videocam:before {
	content: '\ec14';
}
.prism-mat-sharp.volume_down:before {
	content: '\ec15';
}
.prism-mat-sharp.volume_mute:before {
	content: '\ec16';
}
.prism-mat-sharp.volume_off:before {
	content: '\ec17';
}
.prism-mat-sharp.volume_up:before {
	content: '\ec18';
}
.prism-mat-sharp.web_asset:before {
	content: '\ec19';
}
.prism-mat-sharp.web:before {
	content: '\ec1b';
}
.prism-mat-sharp.info_outline:before {
	content: '\e900';
}
.prism-mat-sharp.error_outline {
	display: inline-block;
	transform: rotate(180deg);
}
.prism-mat-sharp.error_outline:before {
	content: '\e900';
}
.prism-mat-sharp.add1:before {
	content: '\ec1a';
}
.prism-mat-sharp.error:before {
	content: '\ec1c';
}
.prism-mat-sharp.notification_important:before {
	content: '\ec1d';
}
.prism-mat-sharp.warning_amber:before {
	content: '\ec1e';
}
.prism-mat-sharp.warning:before {
	content: '\ec1f';
}
.prism-mat-sharp.d_rotation:before {
	content: '\ec20';
}
.prism-mat-sharp.accessibility_new:before {
	content: '\ec21';
}
.prism-mat-sharp.accessibility:before {
	content: '\ec22';
}
.prism-mat-sharp.accessible_forward:before {
	content: '\ec23';
}
.prism-mat-sharp.accessible:before {
	content: '\ec24';
}
.prism-mat-sharp.account_balance_wallet:before {
	content: '\ec25';
}
.prism-mat-sharp.account_balance:before {
	content: '\ec26';
}
.prism-mat-sharp.account_box:before {
	content: '\ec27';
}
.prism-mat-sharp.account_circle:before {
	content: '\ec28';
}
.prism-mat-sharp.add_shopping_cart:before {
	content: '\ec29';
}
.prism-mat-sharp.alarm_add:before {
	content: '\ec2a';
}
.prism-mat-sharp.alarm_off:before {
	content: '\ec2b';
}
.prism-mat-sharp.alarm_on:before {
	content: '\ec2c';
}
.prism-mat-sharp.alarm:before {
	content: '\ec2d';
}
.prism-mat-sharp.all_inbox:before {
	content: '\ec2e';
}
.prism-mat-sharp.all_out:before {
	content: '\ec2f';
}
.prism-mat-sharp.android:before {
	content: '\ec30';
}
.prism-mat-sharp.announcement:before {
	content: '\ec31';
}
.prism-mat-sharp.arrow_right_alt:before {
	content: '\ec32';
}
.prism-mat-sharp.aspect_ratio:before {
	content: '\ec33';
}
.prism-mat-sharp.assessment:before {
	content: '\ec34';
}
.prism-mat-sharp.assignment_ind:before {
	content: '\ec35';
}
.prism-mat-sharp.assignment_late:before {
	content: '\ec36';
}
.prism-mat-sharp.assignment_return:before {
	content: '\ec37';
}
.prism-mat-sharp.assignment_returned:before {
	content: '\ec38';
}
.prism-mat-sharp.assignment_turned_in:before {
	content: '\ec39';
}
.prism-mat-sharp.assignment:before {
	content: '\ec3a';
}
.prism-mat-sharp.autorenew:before {
	content: '\ec3b';
}
.prism-mat-sharp.backup:before {
	content: '\ec3c';
}
.prism-mat-sharp.book:before {
	content: '\ec3d';
}
.prism-mat-sharp.bookmark_border:before {
	content: '\ec3e';
}
.prism-mat-sharp.bookmark:before {
	content: '\ec3f';
}
.prism-mat-sharp.bookmarks:before {
	content: '\ec40';
}
.prism-mat-sharp.bug_report:before {
	content: '\ec41';
}
.prism-mat-sharp.build:before {
	content: '\ec42';
}
.prism-mat-sharp.cached:before {
	content: '\ec43';
}
.prism-mat-sharp.calendar_today:before {
	content: '\ec44';
}
.prism-mat-sharp.calendar_view_day:before {
	content: '\ec45';
}
.prism-mat-sharp.camera_enhance:before {
	content: '\ec46';
}
.prism-mat-sharp.card_giftcard:before {
	content: '\ec47';
}
.prism-mat-sharp.card_membership:before {
	content: '\ec48';
}
.prism-mat-sharp.card_travel:before {
	content: '\ec49';
}
.prism-mat-sharp.change_history:before {
	content: '\ec4a';
}
.prism-mat-sharp.check_circle_outline:before {
	content: '\ec4b';
}
.prism-mat-sharp.check_circle:before {
	content: '\ec4c';
}
.prism-mat-sharp.chrome_reader_mode:before {
	content: '\ec4d';
}
.prism-mat-sharp.class:before {
	content: '\ec4e';
}
.prism-mat-sharp.code:before {
	content: '\ec4f';
}
.prism-mat-sharp.commute:before {
	content: '\ec50';
}
.prism-mat-sharp.compare_arrows:before {
	content: '\ec51';
}
.prism-mat-sharp.contact_support:before {
	content: '\ec52';
}
.prism-mat-sharp.copyright:before {
	content: '\ec53';
}
.prism-mat-sharp.credit_card:before {
	content: '\ec54';
}
.prism-mat-sharp.dashboard:before {
	content: '\ec55';
}
.prism-mat-sharp.date_range:before {
	content: '\ec56';
}
.prism-mat-sharp.delete_forever:before {
	content: '\ec57';
}
.prism-mat-sharp.delete_outline:before {
	content: '\ec58';
}
.prism-mat-sharp.delete:before {
	content: '\ec59';
}
.prism-mat-sharp.description:before {
	content: '\ec5a';
}
.prism-mat-sharp.dns:before {
	content: '\ec5b';
}
.prism-mat-sharp.done_all:before {
	content: '\ec5c';
}
.prism-mat-sharp.done_outline:before {
	content: '\ec5d';
}
.prism-mat-sharp.done:before {
	content: '\ec5e';
}
.prism-mat-sharp.donut_large:before {
	content: '\ec5f';
}
.prism-mat-sharp.donut_small:before {
	content: '\ec60';
}
.prism-mat-sharp.drag_indicator:before {
	content: '\ec61';
}
.prism-mat-sharp.eject:before {
	content: '\ec62';
}
.prism-mat-sharp.euro_symbol:before {
	content: '\ec63';
}
.prism-mat-sharp.event_seat:before {
	content: '\ec64';
}
.prism-mat-sharp.event:before {
	content: '\ec65';
}
.prism-mat-sharp.exit_to_app:before {
	content: '\ec66';
}
.prism-mat-sharp.explore_off:before {
	content: '\ec67';
}
.prism-mat-sharp.explore:before {
	content: '\ec68';
}
.prism-mat-sharp.extension:before {
	content: '\ec69';
}
.prism-mat-sharp.face_unlock:before {
	content: '\ec6a';
}
.prism-mat-sharp.face:before {
	content: '\ec6b';
}
.prism-mat-sharp.favorite_border:before {
	content: '\ec6c';
}
.prism-mat-sharp.favorite:before {
	content: '\ec6d';
}
.prism-mat-sharp.feedback:before {
	content: '\ec6e';
}
.prism-mat-sharp.find_in_page:before {
	content: '\ec6f';
}
.prism-mat-sharp.find_replace:before {
	content: '\ec70';
}
.prism-mat-sharp.fingerprint:before {
	content: '\ec71';
}
.prism-mat-sharp.flight_land:before {
	content: '\ec72';
}
.prism-mat-sharp.flight_takeoff:before {
	content: '\ec73';
}
.prism-mat-sharp.flip_to_back:before {
	content: '\ec74';
}
.prism-mat-sharp.flip_to_front:before {
	content: '\ec75';
}
.prism-mat-sharp.g_translate:before {
	content: '\ec76';
}
.prism-mat-sharp.gavel:before {
	content: '\ec77';
}
.prism-mat-sharp.get_app:before {
	content: '\ec78';
}
.prism-mat-sharp.gif:before {
	content: '\ec79';
}
.prism-mat-sharp.grade:before {
	content: '\ec7a';
}
.prism-mat-sharp.group_work:before {
	content: '\ec7b';
}
.prism-mat-sharp.help_outline:before {
	content: '\ec7c';
}
.prism-mat-sharp.help:before {
	content: '\ec7d';
}
.prism-mat-sharp.highlight_off:before {
	content: '\ec7e';
}
.prism-mat-sharp.history:before {
	content: '\ec7f';
}
.prism-mat-sharp.home:before {
	content: '\ec80';
}
.prism-mat-sharp.horizontal_split:before {
	content: '\ec81';
}
.prism-mat-sharp.hourglass_empty:before {
	content: '\ec82';
}
.prism-mat-sharp.hourglass_full:before {
	content: '\ec83';
}
.prism-mat-sharp.http:before {
	content: '\ec84';
}
.prism-mat-sharp.https:before {
	content: '\ec85';
}
.prism-mat-sharp.important_devices:before {
	content: '\ec86';
}
.prism-mat-sharp.info_outline1:before {
	content: '\ec87';
}
.prism-mat-sharp.info:before {
	content: '\ec88';
}
.prism-mat-sharp.input:before {
	content: '\ec89';
}
.prism-mat-sharp.invert_colors:before {
	content: '\ec8a';
}
.prism-mat-sharp.label_important_outline:before {
	content: '\ec8b';
}
.prism-mat-sharp.label_important:before {
	content: '\ec8c';
}
.prism-mat-sharp.label_off:before {
	content: '\ec8d';
}
.prism-mat-sharp.label_outline:before {
	content: '\ec8e';
}
.prism-mat-sharp.label:before {
	content: '\ec8f';
}
.prism-mat-sharp.language:before {
	content: '\ec90';
}
.prism-mat-sharp.launch:before {
	content: '\ec91';
}
.prism-mat-sharp.lightbulb_outline:before {
	content: '\ec92';
}
.prism-mat-sharp.line_style:before {
	content: '\ec93';
}
.prism-mat-sharp.line_weight:before {
	content: '\ec94';
}
.prism-mat-sharp.list:before {
	content: '\ec95';
}
.prism-mat-sharp.lock_open:before {
	content: '\ec96';
}
.prism-mat-sharp.lock_outline:before {
	content: '\ec97';
}
.prism-mat-sharp.lock:before {
	content: '\ec98';
}
.prism-mat-sharp.loyalty:before {
	content: '\ec99';
}
.prism-mat-sharp.markunread_mailbox:before {
	content: '\ec9a';
}
.prism-mat-sharp.maximize:before {
	content: '\ec9b';
}
.prism-mat-sharp.minimize:before {
	content: '\ec9c';
}
.prism-mat-sharp.motorcycle:before {
	content: '\ec9d';
}
.prism-mat-sharp.note_add:before {
	content: '\ec9e';
}
.prism-mat-sharp.offline_bolt:before {
	content: '\ec9f';
}
.prism-mat-sharp.offline_pin:before {
	content: '\eca0';
}
.prism-mat-sharp.opacity:before {
	content: '\eca1';
}
.prism-mat-sharp.open_in_browser:before {
	content: '\eca2';
}
.prism-mat-sharp.open_in_new:before {
	content: '\eca3';
}
.prism-mat-sharp.open_with:before {
	content: '\eca4';
}
.prism-mat-sharp.pageview:before {
	content: '\eca5';
}
.prism-mat-sharp.pan_tool:before {
	content: '\eca6';
}
.prism-mat-sharp.payment:before {
	content: '\eca7';
}
.prism-mat-sharp.perm_camera_mic:before {
	content: '\eca8';
}
.prism-mat-sharp.perm_contact_calendar:before {
	content: '\eca9';
}
.prism-mat-sharp.perm_data_setting:before {
	content: '\ecaa';
}
.prism-mat-sharp.perm_device_information:before {
	content: '\ecab';
}
.prism-mat-sharp.perm_identity:before {
	content: '\ecac';
}
.prism-mat-sharp.perm_media:before {
	content: '\ecad';
}
.prism-mat-sharp.perm_phone_msg:before {
	content: '\ecae';
}
.prism-mat-sharp.perm_scan_wifi:before {
	content: '\ecaf';
}
.prism-mat-sharp.pets:before {
	content: '\ecb0';
}
.prism-mat-sharp.picture_in_picture_alt:before {
	content: '\ecb1';
}
.prism-mat-sharp.picture_in_picture:before {
	content: '\ecb2';
}
.prism-mat-sharp.play_for_work:before {
	content: '\ecb3';
}
.prism-mat-sharp.polymer:before {
	content: '\ecb4';
}
.prism-mat-sharp.power_settings_new:before {
	content: '\ecb5';
}
.prism-mat-sharp.pregnant_woman:before {
	content: '\ecb6';
}
.prism-mat-sharp.print:before {
	content: '\ecb7';
}
.prism-mat-sharp.query_builder:before {
	content: '\ecb8';
}
.prism-mat-sharp.question_answer:before {
	content: '\ecb9';
}
.prism-mat-sharp.receipt:before {
	content: '\ecba';
}
.prism-mat-sharp.record_voice_over:before {
	content: '\ecbb';
}
.prism-mat-sharp.redeem:before {
	content: '\ecbc';
}
.prism-mat-sharp.remove_shopping_cart:before {
	content: '\ecbd';
}
.prism-mat-sharp.reorder:before {
	content: '\ecbe';
}
.prism-mat-sharp.report_problem:before {
	content: '\ecbf';
}
.prism-mat-sharp.restore_from_trash:before {
	content: '\ecc0';
}
.prism-mat-sharp.restore_page:before {
	content: '\ecc1';
}
.prism-mat-sharp.restore:before {
	content: '\ecc2';
}
.prism-mat-sharp.room:before {
	content: '\ecc3';
}
.prism-mat-sharp.rounded_corner:before {
	content: '\ecc4';
}
.prism-mat-sharp.rowing:before {
	content: '\ecc5';
}
.prism-mat-sharp.schedule:before {
	content: '\ecc6';
}
.prism-mat-sharp.search:before {
	content: '\ecc7';
}
.prism-mat-sharp.settings_applications:before {
	content: '\ecc8';
}
.prism-mat-sharp.settings_backup_restore:before {
	content: '\ecc9';
}
.prism-mat-sharp.settings_bluetooth:before {
	content: '\ecca';
}
.prism-mat-sharp.settings_brightness:before {
	content: '\eccb';
}
.prism-mat-sharp.settings_cell:before {
	content: '\eccc';
}
.prism-mat-sharp.settings_ethernet:before {
	content: '\eccd';
}
.prism-mat-sharp.settings_input_antenna:before {
	content: '\ecce';
}
.prism-mat-sharp.settings_input_component:before {
	content: '\eccf';
}
.prism-mat-sharp.settings_input_composite:before {
	content: '\ecd0';
}
.prism-mat-sharp.settings_input_hdmi:before {
	content: '\ecd1';
}
.prism-mat-sharp.settings_input_svideo:before {
	content: '\ecd2';
}
.prism-mat-sharp.settings_overscan:before {
	content: '\ecd3';
}
.prism-mat-sharp.settings_phone:before {
	content: '\ecd4';
}
.prism-mat-sharp.settings_power:before {
	content: '\ecd5';
}
.prism-mat-sharp.settings_remote:before {
	content: '\ecd6';
}
.prism-mat-sharp.settings_voice:before {
	content: '\ecd7';
}
.prism-mat-sharp.settings:before {
	content: '\ecd8';
}
.prism-mat-sharp.shop_two:before {
	content: '\ecd9';
}
.prism-mat-sharp.shop:before {
	content: '\ecda';
}
.prism-mat-sharp.shopping_basket:before {
	content: '\ecdb';
}
.prism-mat-sharp.shopping_cart:before {
	content: '\ecdc';
}
.prism-mat-sharp.speaker_notes_off:before {
	content: '\ecdd';
}
.prism-mat-sharp.speaker_notes:before {
	content: '\ecde';
}
.prism-mat-sharp.spellcheck:before {
	content: '\ecdf';
}
.prism-mat-sharp.star_rate_18px_sharp:before {
	content: '\ece0';
}
.prism-mat-sharp.stars:before {
	content: '\ece1';
}
.prism-mat-sharp.store:before {
	content: '\ece2';
}
.prism-mat-sharp.subject:before {
	content: '\ece3';
}
.prism-mat-sharp.supervised_user_circle:before {
	content: '\ece4';
}
.prism-mat-sharp.supervisor_account:before {
	content: '\ece5';
}
.prism-mat-sharp.swap_horiz:before {
	content: '\ece6';
}
.prism-mat-sharp.swap_horizontal_circle:before {
	content: '\ece7';
}
.prism-mat-sharp.swap_vert:before {
	content: '\ece8';
}
.prism-mat-sharp.swap_vertical_circle:before {
	content: '\ece9';
}
.prism-mat-sharp.system_vertical_alt:before {
	content: '\ecea';
}
.prism-mat-sharp.tab_unselected:before {
	content: '\eceb';
}
.prism-mat-sharp.tab:before {
	content: '\ecec';
}
.prism-mat-sharp.text_rotate_up:before {
	content: '\eced';
}
.prism-mat-sharp.text_rotate_vertical:before {
	content: '\ecee';
}
.prism-mat-sharp.text_rotation_angle_down:before {
	content: '\ecef';
}
.prism-mat-sharp.text_rotation_angle_up:before {
	content: '\ecf0';
}
.prism-mat-sharp.text_rotation_down:before {
	content: '\ecf1';
}
.prism-mat-sharp.text_rotation_none:before {
	content: '\ecf2';
}
.prism-mat-sharp.theaters:before {
	content: '\ecf3';
}
.prism-mat-sharp.thumb_down:before {
	content: '\ecf4';
}
.prism-mat-sharp.thumb_up:before {
	content: '\ecf5';
}
.prism-mat-sharp.thumbs_up_down:before {
	content: '\ecf6';
}
.prism-mat-sharp.timeline:before {
	content: '\ecf7';
}
.prism-mat-sharp.toc:before {
	content: '\ecf8';
}
.prism-mat-sharp.today:before {
	content: '\ecf9';
}
.prism-mat-sharp.toll:before {
	content: '\ecfa';
}
.prism-mat-sharp.touch_app:before {
	content: '\ecfb';
}
.prism-mat-sharp.track_changes:before {
	content: '\ecfc';
}
.prism-mat-sharp.translate:before {
	content: '\ecfd';
}
.prism-mat-sharp.trending_down:before {
	content: '\ecfe';
}
.prism-mat-sharp.trending_flat:before {
	content: '\ecff';
}
.prism-mat-sharp.trending_up:before {
	content: '\ed00';
}
.prism-mat-sharp.turned_in_not:before {
	content: '\ed01';
}
.prism-mat-sharp.turned_in:before {
	content: '\ed02';
}
.prism-mat-sharp.update:before {
	content: '\ed03';
}
.prism-mat-sharp.verified_user:before {
	content: '\ed04';
}
.prism-mat-sharp.vertical_split:before {
	content: '\ed05';
}
.prism-mat-sharp.view_agenda:before {
	content: '\ed06';
}
.prism-mat-sharp.view_array:before {
	content: '\ed07';
}
.prism-mat-sharp.view_carousel:before {
	content: '\ed08';
}
.prism-mat-sharp.view_column:before {
	content: '\ed09';
}
.prism-mat-sharp.view_day:before {
	content: '\ed0a';
}
.prism-mat-sharp.view_headline:before {
	content: '\ed0b';
}
.prism-mat-sharp.view_list:before {
	content: '\ed0c';
}
.prism-mat-sharp.view_module:before {
	content: '\ed0d';
}
.prism-mat-sharp.view_quilt:before {
	content: '\ed0e';
}
.prism-mat-sharp.view_stream:before {
	content: '\ed0f';
}
.prism-mat-sharp.view_week:before {
	content: '\ed10';
}
.prism-mat-sharp.visibility_off:before {
	content: '\ed11';
}
.prism-mat-sharp.visibility:before {
	content: '\ed12';
}
.prism-mat-sharp.voice_over_off:before {
	content: '\ed13';
}
.prism-mat-sharp.watch_later:before {
	content: '\ed14';
}
.prism-mat-sharp.work_off:before {
	content: '\ed15';
}
.prism-mat-sharp.work_outline:before {
	content: '\ed16';
}
.prism-mat-sharp.work:before {
	content: '\ed17';
}
.prism-mat-sharp.youtube_searched_for:before {
	content: '\ed18';
}
.prism-mat-sharp.zoom_in:before {
	content: '\ed19';
}
.prism-mat-sharp.zoom_out:before {
	content: '\ed1a';
}
