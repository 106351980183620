@use 'sass:map';
@use '@angular/material' as mat;

@mixin override-style() {
	.mat-expansion-panel {
		box-shadow: none !important;
		border-radius: 0 !important;
	}

	.mat-expansion-panel-header {
		padding: 0 16px !important;
	}

	.mat-expansion-panel-body {
		padding: 0 16px 16px !important;
	}
}

@mixin override-theme($theme) {
	$color-config: mat.get-color-config($theme);
	$foreground: map.get($color-config, foreground);
	$accent: map.get($color-config, accent);

	// Apply top border to all mat-expansion-panel except first
	.mat-accordion > .mat-expansion-panel:not(:first-child) {
		border-top: 1px solid mat.get-color-from-palette($foreground, divider);
	}

	mat-sidenav {
		.mat-mdc-list-item,
		.mat-mdc-list-option {
			&.active,
			&[aria-selected='true'] {
				background-color: mat.get-color-from-palette($accent) !important;

				@if map.get($color-config, is-dark) {
					color: mat.get-color-from-palette(mat.get-color-from-palette($accent, contrast), 50);
				}
			}
		}
	}
}
