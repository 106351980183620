@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme, $led-colors) {
	$config: mat.get-color-config($theme);
	$foreground: map.get($config, foreground);
	$background: map.get($config, background);
	$primary: map.get($config, primary);
	$accent: map.get($config, accent);

	$border-color: mat.get-color-from-palette($foreground, divider);
	$light-background: mat.get-color-from-palette($background, card);
	$new-device: map.get($led-colors, 'ok');
	$selected-item-text-color: black;

	sh-online-devices {
		.sh-header-toolbar {
			z-index: 4;
			border-bottom: 1px solid $border-color;
		}

		.sh-led-icon-bad {
			color: map.get($led-colors, 'bad');
		}

		.sh-led-icon-good {
			color: map.get($led-colors, 'good');
		}

		.sh-led-icon-ok {
			color: map.get($led-colors, 'ok');
		}

		.sh-device-table-container {
			background-color: $light-background;

			@if map.get($config, is-dark) {
				background-color: #303031;
			}
		}

		.sh-new-devices-warning {
			border: 1.5px solid $new-device;
			background-color: rgba($new-device, 0.075);

			@if map.get($config, is-dark) {
				background-color: #303031;
			}

			.sh-new-button {
				background: $new-device;
			}
		}

		#sh-no-online-devices-container {
			color: mat.get-color-from-palette($foreground, secondary-text);
		}

		#sh-no-online-devices-image-dark {
			display: none;
		}

		@if map.get($config, is-dark) {
			#sh-no-online-devices-image {
				display: none;
			}

			#sh-no-online-devices-image-dark {
				display: block;
			}
		}

		.sh-online-devices-room-link {
			border-bottom: 2px solid mat.get-color-from-palette($accent);
			&:hover {
				@if map.get($config, is-dark) {
					color: $selected-item-text-color;
				}
				background-color: mat.get-color-from-palette($accent, 0.7);
				border: none;
			}
		}
	}
}

@mixin theme($theme, $led-colors) {
	$color-config: mat.get-color-config($theme);
	@if $color-config != null {
		@include color($theme, $led-colors);
	}
}
