@use 'sass:map';
@use '@angular/material' as mat;

@mixin override-style() {
	$font-size: 14px;
	$line-height: 48px;
	.mat-mdc-menu-item {
		font-family: Roboto, Helvetica Neue, sans-serif !important;
		font-size: $font-size !important;
		line-height: $line-height !important;

		.mdc-list-item__primary-text {
			font-family: Roboto, Helvetica Neue, sans-serif !important;
			font-size: $font-size !important;
			line-height: $line-height !important;
		}
	}
}
