@mixin mat-dialog-override() {
	.mat-dialog-container {
		border-radius: 8px !important;
	}

	.sh-table-dialog {
		.mat-dialog-container {
			padding-left: 0;
			padding-right: 0;
		}
		h1 {
			padding-left: 24px;
			padding-right: 24px;
		}
	}

	.mat-dialog-content {
		.mat-body-2 {
			opacity: 0.54;
		}
	}

	mat-dialog-container,
	.mat-dialog-container,
	[mat-dialog-container] {
		position: relative;

		mat-progress-bar,
		.mat-progress-bar,
		[mat-progress-bar] {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

@mixin mat-dialog-theme-override($theme) {
}
